import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nps-guidelines',
  templateUrl: './nps-guidelines.component.html',
  styleUrls: ['./nps-guidelines.component.scss']
})
export class NpsGuidelinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
