<div>
    <form class="form" [formGroup]="insightsForm" autocomplete="new-password">
        <h3 class="mt-2 mb-0">{{'insights_lab.filter'|translate}}</h3>
        <mat-form-field class="mt-2">
            <mat-label>{{'insights_lab.date_range'|translate}}</mat-label>
            <mat-date-range-input (mousedown)="picker.open(); $event.preventDefault()" (touchstart)="picker.open(); $event.preventDefault()" [rangePicker]="picker" [max]="maxDate">
               <input matStartDate formControlName="startDate" placeholder="{{'insights_lab.start_date'|translate}}" readonly>
               <input matEndDate formControlName="endDate" placeholder="{{'insights_lab.end_date'|translate}}" readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
            <mat-date-range-picker touchUi #picker (closed)="removeValidators('startDate');removeValidators('endDate');onClosedSetEndDate()"></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field *ngIf="isShowStores()" class="auth-full-width">
            <mat-label>Select store</mat-label>
            <mat-select formControlName="storeIds" multiple>
                <mat-option [value]="store.id" *ngFor="let store of storeList">{{store.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="f.storeIds?.invalid">{{getErrMessage(f.store)}}</mat-error>
        </mat-form-field>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.gender'|translate}}</mat-label>
                    <mat-select formControlName="gender">
                    <mat-option [value]="gender.id" *ngFor="let gender of genderList">{{gender.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.gender?.invalid">{{getErrMessage(f.gender)}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.age_group'|translate}}</mat-label>
                    <mat-select formControlName="ageGroup">
                    <mat-option [value]="ageGroup.id" *ngFor="let ageGroup of ageGroupList">{{ageGroup.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.ageGroup?.invalid">{{getErrMessage(f.ageGroup)}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.nps_rating'|translate}}</mat-label>
                    <mat-select formControlName="npsRating">
                    <mat-option [value]="npsRating.id" *ngFor="let npsRating of npsRatingList">{{npsRating.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.npsRating?.invalid">{{getErrMessage(f.npsRating)}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.overall'|translate}}</mat-label>
                    <mat-select formControlName="starRating">
                    <mat-option [value]="starRating.id" *ngFor="let starRating of starRatingList">{{starRating.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.starRating?.invalid">{{getErrMessage(f.starRating)}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <button mat-stroked-button color="accent" (click)="closeBottomSheet($event)">{{'insights_lab.reset'|translate}}</button>
            <button mat-raised-button color="primary" (click)="applyFilter($event)">{{'insights_lab.filter'|translate}}</button>
        </div>
    </form>
</div>