import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from '../../constants';
import { UtilService } from '../../services/utility/util.service';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-add-participant-dialog',
  templateUrl: './add-participant-dialog.component.html',
  styleUrls: ['./add-participant-dialog.component.scss']
})
export class AddParticipantDialogComponent implements OnInit {
  isAddParticipant:boolean = false;
  participantForm!:UntypedFormGroup;
  roleList:any[] = [];
  userData:any;
  constructor(
    public dialogRef: MatDialogRef<AddParticipantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder:UntypedFormBuilder,
    private validation: ValidationService,
    private util:UtilService,
  ) {
    this.isAddParticipant = data.action == 'add'
  }

  ngOnInit(): void {
    console.log(this.data,'....')
    this.userData = this.data?.userData || {};
    this.participantForm = this.formBuilder.group({
      name: [this.userData?.name, [Validators.required, this.validation.alphaNumValidator]],
      email_mobile: [this.userData?.email_mobile || '',[Validators.required, this.validation.emailOrNumber]],
      role_id: [this.userData.role_id?.toString() || '',[Validators.required]],
      callId: this.userData?.callId,
      meetingId: this.userData?.meetingId,
      id: this.userData?.id
    });
    this.roleList = Constants.ROLES_LIST;
  }

  onOkClick(){
    let memberData = {...this.participantForm.value}
    this.util.removeNullVariable(memberData)
    this.dialogRef.close({
      action:this.data.action,
      participantData:memberData,
      index:this.data.index,
      isEmailChanged:this.userData?.email_mobile != this.participantForm.get('email_mobile')?.value || ''
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  get f(){
    return this.participantForm.controls;
  }

  getErrMessage(abstractControl:AbstractControl) {
    return this.validation.getErrorMessage(abstractControl);
  }

  openSnackBar(message: string, action: string) {
    this.util.openSnackBar(message, action);
  }
}
