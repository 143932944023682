import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLayoutComponent } from './core/app-layout/app-layout.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { MaterialModule } from './modules/material/material.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './shared/services/http-interceptor/http-config.interceptor';
import { ErrorConfigInterceptor } from './shared/services/error-interceptor/error-config.interceptor';
import { FilterBottomSheetComponent } from './shared/components/filter-bottom-sheet/filter-bottom-sheet.component';
import { AddParticipantDialogComponent } from './shared/components/add-participant-dialog/add-participant-dialog.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { TitleCasePipe } from '@angular/common';
import { CustomSnackbarComponent } from './shared/components/custom-snackbar/custom-snackbar.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { CustomerFilterSheetComponent, DateMonthHeader } from './shared/components/customer-filter-sheet/customer-filter-sheet.component';
import { NpsGuidelinesComponent } from './shared/components/nps-guidelines/nps-guidelines.component';
import { QrCodeComponent } from './shared/components/qr-code/qr-code.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { PiAppLayoutComponent } from './core/pi-app-layout/pi-app-layout.component';
import { SubscribeModalComponent } from './shared/components/subscribe-modal/subscribe-modal.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    HeaderComponent,
    FooterComponent,
    FilterBottomSheetComponent,
    AddParticipantDialogComponent,
    ConfirmDialogComponent,
    CustomSnackbarComponent,
    TermsAndConditionsComponent,
    CustomerFilterSheetComponent,
    DateMonthHeader,
    NpsGuidelinesComponent,
    QrCodeComponent,
    PiAppLayoutComponent,
    SubscribeModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgAddToCalendarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    Title,
    TitleCasePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorConfigInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
