<div>
    <form class="form" [formGroup]="filterForm" autocomplete="new-password">
        <h3 class="mt-2 mb-0">{{'insights_lab.filter'|translate}}</h3>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.by_age'|translate}}</mat-label>
                    <mat-select formControlName="age">
                    <mat-option [value]="ageGroup.id" *ngFor="let ageGroup of ageGroupList">{{ageGroup.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.age?.invalid">{{getErrMessage(f.age)}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.gender'|translate}}</mat-label>
                    <mat-select formControlName="gender">
                    <mat-option [value]="gender.id" *ngFor="let gender of genderList">{{gender.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.gender?.invalid">{{getErrMessage(f.gender)}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <input matInput placeholder="{{'insights_lab.name'|translate}}" formControlName="name"/>
                    <mat-error *ngIf="f.name?.invalid">{{getErrMessage(f.name)}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="auth-full-width">
                    <mat-label>{{'insights_lab.dob'|translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" (mousedown)="picker.open(); $event.preventDefault" (touchstart)="picker.open(); $event.preventDefault()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker [calendarHeaderComponent]="dateMonthHeader"></mat-datepicker>
                    <mat-error *ngIf="f.dateOfBirth?.invalid">{{getErrMessage(f.dateOfBirth)}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 p-0">
            <mat-form-field class="auth-full-width">
                <mat-label>{{'insights_lab.nps'|translate}}</mat-label>
                <mat-select multiple="true" formControlName="nps">
                <mat-option [value]="nps.id" *ngFor="let nps of npsList">{{nps.name}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="f.nps?.invalid">{{getErrMessage(f.nps)}}</mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <button mat-stroked-button color="accent" (click)="closeBottomSheet($event)">{{'insights_lab.reset'|translate}}</button>
            <button mat-raised-button color="primary" (click)="applyFilter($event)">{{'insights_lab.filter'|translate}}</button>
        </div>
    </form>
</div>