// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://aiplus-api.mysurveyhub.com",
  googleApiKey: 'AIzaSyD2TxR6aW8BfX6liHfk1fKGLWGyxQ2pUO4',
  GA_TRACKING_ID: '',
  razorpayKey: 'rzp_test_Ln4oKIo2saFM7F',
  disposableDomainList: [],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
