import { Component, OnInit } from '@angular/core';
import { UtilService } from './shared/services/utility/util.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Megrow';
  loading$ = this.util.loading$;
  
  constructor(
    public util: UtilService, 
    private titleService: Title, 
    private router:Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (environment.production) {
      this.addGAScriptCode()
      this.addGAScript();
      this.addGANoScript();
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: any) => {
        /** START : Code to Track Page View  */
         gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
         })
        /** END */
      })
    }
  }

  ngOnInit() {
    this.router.events.pipe(  
      filter(event => event instanceof NavigationEnd),  
    ).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute);  
      rt.data.subscribe((data:any) => {    
        this.titleService.setTitle(data.title + ' - Me-Grow')});  
    }); 
  }  
  

  getChild(activatedRoute: ActivatedRoute):any {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  }

  addGAScriptCode() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    let code = 
    `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());`
    gtagScript.innerHTML = code;
    document.head.prepend(gtagScript);
  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id='+environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
  }

  addGANoScript() {
    let gtagNoscript = document.createElement('noscript');
    var iframe = document.createElement('iframe')
    iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id="+environment.GA_TRACKING_ID)
    iframe.setAttribute("height", "0")
    iframe.setAttribute("width", "0")
    iframe.setAttribute("style", "display:none;visibility:hidden")
    gtagNoscript.appendChild(iframe)
    document.body.prepend(gtagNoscript);
  }

}
