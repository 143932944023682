<div class="qr-section">
    <h1 mat-dialog-title class="business-hdg">{{this.data?.title}}</h1>
</div>
<div mat-dialog-content class="qr-body qr-section">
    <div class="qr-content-area">
        <h2>{{'my_qrcode.download_qr_description'| translate}}</h2>
    </div>
    <div class="text-center mt-2 mb-3 business-name">{{this.data?.businessName}}</div>
    <div id="qrContent" #qrContent>
        <div class="qr-main-section">
            <qr-code #qrCode id="qrCode" value="{{baseQrUrl + store_id}}"
                    size="230"
                    errorCorrectionLevel="M"
                    centerImageSrc="../../../assets/images/qrlogo_blue.png"
                    centerImageSize="60"
                    [margin]="0">
            </qr-code>
            <img class="qr-img" src="assets/images/qrlogo_blue.png"/>
        </div>
    </div>
    <div class="mt-4 d-flex flex-column justify-content-center align-items-center">
        <button mat-raised-button color="primary" class="btn-block" (click)="qrPdfGenerate()">{{'my_qrcode.download_qr'|translate}}</button>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button color="warn" (click)="onNoClick()">{{'my_qrcode.close'| translate}}</button>
</div>