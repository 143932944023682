import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UtilService } from '../../services/utility/util.service';
import { ValidationService } from '../../services/validation/validation.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-filter-bottom-sheet',
  templateUrl: './filter-bottom-sheet.component.html',
  styleUrls: ['./filter-bottom-sheet.component.scss']
})
export class FilterBottomSheetComponent implements OnInit {
  insightsForm!:UntypedFormGroup;
  genderList:any[] = [];
  ageGroupList:any[] = [];
  starRatingList:any[] = []
  npsRatingList:any[] = []
  minDate!: Date;
  maxDate!: Date;
  storeList:any[] = []
  roleId:any;
  isOnlyAdmin:boolean = true;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data:any,
    private _bottomSheetRef: MatBottomSheetRef<FilterBottomSheetComponent>,
    private formBuilder: UntypedFormBuilder,
    private util: UtilService,
    private validation: ValidationService,
    private common: CommonService
    ) {
      // console.log('retain:',data)
    const currentDate = new Date();
    this.minDate = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, currentDate.getDate());
    this.maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  ngOnInit(): void {
    this.roleId = this.util.getStoreDetails()?.roleId;
    setTimeout(() => {
      this.genderList = this.common.getGenderListWithAll()
      this.ageGroupList = this.common.getAgeGroupList()
      this.npsRatingList = this.common.getNpsRatingList()
      this.starRatingList = this.common.getStarRatingList()
      this.storeList = this.data.storeList
      this.isOnlyAdmin = this.storeList.filter(store => !store.isAdminStore).length == 0;
    });
    
    this.insightsForm = this.formBuilder.group({
      startDate: [ this.data?.startDate ? new Date(this.data?.startDate) : undefined],
      endDate: [ this.data?.endDate ? new Date(this.data?.endDate) : undefined],
      storeIds: [this.data.storeIds],
      gender:[this.data.gender],
      ageGroup:[this.data.ageGroup],
      npsRating:[this.data.npsRating],
      starRating:[this.data.starRating],
    });
  }

  applyFilter(event: MouseEvent): void {
    let data = this.insightsForm.value;
    if(data.startDate && data.endDate){
      data.startDate = this.util.formatDateAsc(data.startDate);
      data.endDate =  this.util.formatDateAsc(data.endDate);
    }
    this._bottomSheetRef.dismiss(this.insightsForm.value);

    event.preventDefault();
  }

  closeBottomSheet(event: MouseEvent){
    this._bottomSheetRef.dismiss('reset');
    event.preventDefault();
  }

  get f(){
    return this.insightsForm.controls;
  }

  isShowStores(){
    return this.roleId != 2 && !this.isOnlyAdmin
  }

  removeValidators(field:string){
    this.util.removeValidators(this.insightsForm,field)
  }


  onClosedSetEndDate(){
    this.util.onClosedSetEndDate(this.insightsForm)
  }

  getErrMessage(abstractControl:AbstractControl) {
    return this.validation.getErrorMessage(abstractControl);
  }
}
