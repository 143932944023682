import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PagesService } from 'src/app/pages/pages.service';
import { Constants } from '../../constants';
import { UtilService } from '../../services/utility/util.service';

@Injectable({
  providedIn: 'root'
})
export class PageGuard  {
  constructor(
    private router:Router,
    private pages:PagesService,
    private util:UtilService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getCheckplanExist()
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = this.util.getToken();
      if (token) {
        return true;
      } else {
        this.util.redirectToSignin();
        return false;
      }
  }

  getCheckplanExist():Promise<boolean>{
    return new Promise(res => {
      this.pages.checkPlanExist().subscribe((response: any) => {
        if (response.success === true) {
          this.util.setPlanData(response.data)
          res(this.checkSubscriptionDetail(response.data))
        } else {
          this.util.handleStatusErrorCode(response);
          res(false);
        }
        this.util.hide();
      }, (error) => {
        res(false);
        this.util.openSnackBar(Constants.ERROR_MSG.RETRY, Constants.STRING_CONST.TOAST_BTN_NAME);
        this.util.hide();
      });
    })
  }

  checkSubscriptionDetail(data:any){
    const token = this.util.getToken();
    if (!data?.is_business_profile_updated && token) {
      this.router.navigate(['/business-profile'])
      return true;
    } else if (data?.is_plan_exist && token) {
      return true;
    } else {
      this.router.navigate(['/choose-plan'])
      return false;
    }
  }
}
