<div *ngIf="data?.title" class="header">
    <h2 mat-dialog-title>{{data?.title}}</h2>
</div>

<div mat-dialog-content>
    <p>{{data?.message}}</p>
    <div [innerHTML]="data?.template">
    </div>
</div>

<div mat-dialog-actions [align]="'end'">
    <button *ngIf="data?.cancelCaption" mat-button color="warn" [mat-dialog-close]="false">
        {{data?.cancelCaption}}
    </button>
    <button *ngIf="data?.confirmCaption" mat-button cdkFocusInitial color="primary"  [mat-dialog-close]="true">
        {{data?.confirmCaption}}
    </button>
</div>