export const Constants =  {
    URL_CONSTANTS : {
        SIGN_IN: '/signin',
        SIGN_UP: '/signup',
        SIGNIN_OTP: '/signin-otp',
        SIGNUP_OTP: '/signup-otp'
    },
    STRING_CONST : {
        TOAST_BTN_NAME: 'Dismiss',
        COOKIE: {
            PI_STORE_NAME: 'pi_store_data',
            PI_STORE_MOBILE: 'pi_store_mobile'
        },
        MONTHLY_PLAN_ID: '2',
	TRIAL_PLAN_ID: [1,4],
        INVALID_SOME_CONTACT: 1671,
    },
    businessSizeList : [
        {name:'1 employee/self',id:1},
        {name:'2-5 employees',id:2},
        {name:'6-10 employees',id:3},
        {name:'more than 10 employees',id:4},
    ],
    ERROR_MSG : {
        INVALID_EMAIL_MOBILE: 'Please enter valid Email or Mobile Number',
        INVALID_EMAIL: 'Enter valid Email',
        INVALID_MOBILE: 'Enter valid Mobile Number',
        INVALID_BUSINESS_NAME: 'Enter valid Business Name',
        INVALID_CONTACT_NAME: 'Enter valid Contact Name',
        INVALID_ADDRESS: 'Enter valid Address Line ',
        INVALID_YEAR_ESTABLISHMENT: 'Enter valid Year',
        INVALID_PINCODE: 'Enter valid Pincode',
        INVALID_GST: 'Enter valid GST Number',
        INVALID_PAN: 'Enter valid PAN Number',
        INVALID_NAME: 'Enter valid Name',
        INVALID_CUS_NAME: 'Name of customer is invalid. The name must not contain numbers.',
        INVALID_CALL_TITLE: 'Enter valid Call Title',
        INAVLID_TIME: 'Call date time should be greater',
        INVALID_TIME_SCHEDULE_MSG: 'Erghh! It seems you’re scheduling a call after your account expiration. Renew Now',
        INVALID_TIME_RESCHEDULE_MSG: 'Erghh! It seems you’re rescheduling a call after your account expiration. Renew Now',
        INVALID_LINK: 'Please enter valid Link',
        INVALID_CITY: 'Please select City',
        INVALID_INDUSTRY: 'Please select Industry',
        INVALID_BUSINESS_SIZE: 'Please select the Business Size',
        INVALID_FIRSTNAME: 'Enter valid First Name',
        INVALID_LASTNAME: 'Enter valid Last Name',
        INVALID_BILLING_ADDRESS: 'Enter valid Billing Address',
        INVALID_MOBILE_NO: 'Enter valid Mobile Number',
        INVALID_LIMIT: 'Limit should be more than or equal to 1',
        RETRY: 'Please retry again',
        TRIAL_MSG: 'Oops! Seems you’ve already finished your allotted feedbacks, contact <a href="mailto:support@me-grow.com">support@me-grow.com</a>',
        VERIFY_TOOLTIP_MSG: 'Click on save to submit',
    },
    LAUNCH_TYPE : {
        QR: '1',
        MOBILE: '2'
    },
    COLORS : {
        LIGHT_GREEN: '#41ff6c',
        LIGHT_YELLOW: '#ffd965',
        LIGHT_RED: '#fb665b',
        GREEN: '#28e954',
        YELLOW: '#ffc107',
        RED: '#f44336',
    },
    backgroundColorList: [
        '#fcba03',
        '#4c8a45',
        '#bf0808',
        '#625bc2',
        '#d6800f',
        '#875F9A',
        '#C93756',//7
        '#5caf93',
        '#adad2a',
        '#b34b4b',
        '#add8e6',
        '#8e6e8e',
        '#43b643',
        '#236338',
        '#ad351a',
        '#2e2b64',
        '#4d0028',
        '#00ffcc',
        '#009999',
        '#ff8533'
    ],
    ROLES_LIST: [
        {id:'1',name:'Moderator'},
        {id:'2',name:'Participant'},
        {id:'3',name:'Client'},
    ],
    ROLES_IDS: {
        Moderator: '1',
        Participant: '2',
        Client: '3',
    },
    REGEX: {
        EMAIL_MOBILE: /^([A-Za-z0-9._%+-]+@[A-Za-z0-9._]+\.[A-Za-z]{2,4})$|^([0-9]{10,10})$/,
        EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9._]+\.[A-Za-z]{2,4}$/,
        MOBILE: /^[0-9]{10,10}$/,
        ONLY_NUMBER: /^-?(0|[1-9]\d*)?$/,
        ACCEPT_MULTI_LANGUAGE: /\p{L}+/gmu,
        ALPHA_NUM: /^([a-z A-Z0-9]{1,100})*$/,
        ALPHA_NUMERIC: /^([a-z A-Z0-9]{2,100})*$/,
        ALPHA_MIN_MAX: /^([a-zA-Z ]{2,100})+$/,
        ALPHA_NUMERIC_SPECIAL_CHAR: /^([a-zA-Z0-9-\s~`!@#$%^&*()_+=\[\]\\<>`]{2,100})+$/,
        LINK: /^(?:(http(s)?|HTTP(S)?):\/\/)?((www|WWW).)?[a-zA-Z0-9]+\.[a-zA-Z]+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        ALPHA_NUMERIC_SPECIAL_CHAR_WITH_SPACE: /^([a-z A-Z0-9~`!@#$%^&*()_+=\;-\\-`.+,\"]{2,100})$/,
        REQUIRED: /[-~]*$/,
        YEAR: /(?:(?:18|19|20|21)[0-9]{2}$)/,
        PINCODE: /^[0-9]{6}$/,
        GST:/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        DIGITS: /^[0-9]*$/,
        MAX_FOUR_DIGIT: /^[0-9]{0,4}$/,
        MAX_SIX_DIGIT: /^[0-9]{0,6}$/,
        MAX_TEN_DIGIT: /^[0-9]{0,10}$/,
        DIGIT_ONLY: /^\d+$/,
    },
    PAYMENT_STATUS: {
        PLAN_PURCHASED_PENDING: 1659,
        PLAN_PURCHASED_FAILED: 1654
    },
    VERIFICATION_STATUS: {
        NOT_VERIFIED: 0,
        VERIFIED: 1,
        VERIFIED_NOT_SUBMITTED: 2,
        INVALID: 3
    },
    LANGUAGE_LIST: [
        {name:'English', shortCode:'en', id: 1},
        {name:'हिन्दी', shortCode:'hi', id: 2},
        {name:'ಕನ್ನಡ', shortCode:'kn', id: 9},
        {name:'தமிழ்', shortCode:'tm', id: 5},
        {name:'తెలుగు', shortCode:'tl', id: 4},
        {name:'मराठी', shortCode:'mr', id: 3},
        {name:'മലയാളം', shortCode:'ml', id: 7},
    ]
} 
type rolesOptions = {
    [key: string]: string
}
export const ROLES:rolesOptions = {
    1 :'Moderator',
    2 :'Participant',
    3 :'Client',
}
