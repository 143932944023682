<h1 mat-dialog-title>{{isAddParticipant ? 'Add Member' : 'Edit Member'}}</h1>
<div mat-dialog-content>
    <form class="form" [formGroup]="participantForm" autocomplete="off">
        <div class="mt-2">
            <mat-form-field class="auth-full-width">
                <input matInput placeholder="Name" formControlName="name" maxlength="100" required>
                <mat-error *ngIf="f.name?.invalid">{{getErrMessage(f.name)}}</mat-error>
            </mat-form-field>
            <mat-form-field class="auth-full-width">
                <input matInput placeholder="Email/Mobile Number" formControlName="email_mobile" required>
                <mat-error *ngIf="f.email_mobile?.invalid">{{getErrMessage(f.email_mobile)}}</mat-error>
              </mat-form-field>
            <mat-form-field class="auth-full-width">
                <mat-label>Role</mat-label>
                <mat-select formControlName="role_id" required>
                  <mat-option [value]="role.id" *ngFor="let role of roleList">{{role.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.role_id?.invalid">{{getErrMessage(f.role_id)}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button color="warn" (click)="onNoClick()">Cancel</button>
  <button mat-button color="primary" cdkFocusInitial [disabled]="participantForm.invalid" (click)="onOkClick()">Submit</button>
</div>