import { Component, OnInit } from '@angular/core';
import { PiService } from 'src/app/personal-information/pi.service';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-pi-app-layout',
  templateUrl: './pi-app-layout.component.html',
  styleUrls: ['./pi-app-layout.component.scss']
})
export class PiAppLayoutComponent implements OnInit {

  constructor(
    private common: CommonService,
    private piService: PiService
  ) { }

  ngOnInit(): void {
    this.common.setLanguage(this.piService.getLanguage())
  }

}
