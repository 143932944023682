import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './core/app-layout/app-layout.component';
import { PageGuard } from './shared/guards/guard-page/page.guard';
import { AuthGuard } from './shared/guards/guard/auth.guard';
import { PiAppLayoutComponent } from './core/pi-app-layout/pi-app-layout.component';

const routes: Routes = [
  
  { path: 'qr', loadChildren: () => import('./qr-download/qr-download.module').then(m => m.QrDownloadModule) },
  { path: 'survey', component:PiAppLayoutComponent, loadChildren: () => import('./personal-information/personal-information.module').then(m => m.PersonalInformationModule) },
  { path: '', redirectTo: '/signup', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), },
  { path: '', component:AppLayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canLoad:[PageGuard] },
  // { path: 'error', component: ErrorComponent },
  // { path: 'linkExpired', component: LinkExpiredComponent },
  // { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
