import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(
    private translate: TranslateService
  ) { }

  emailValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.EMAIL)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  mobileValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.MOBILE)) {
      return null;
    } else {
      return { invalidMobileNum: true };
    }
  }

  allZeroMobileValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val != '0000000000') {
      return null;
    } else {
      return { invalidMobileNum: true };
    }
  }

  mobileListValidator(control:any) {
    let numbers:string[] = control.value?.toString().split(',');
    let isNumbersValid = numbers?.every(number => number.match(Constants.REGEX.MOBILE) && number != '0000000000')
    if (control.value && isNumbersValid) {
      return null;
    } else {
      return { invalidMobileListNum: true };
    }
  }

  isNumbersValid(value:string,regex:any):boolean{
    let numbers:string[] = value?.toString().split(',');
    return numbers.every(number => number.match(regex))
  }

  onlyNumber(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ONLY_NUMBER)) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  businessNameValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.length > 1) {
      return null;
    } else {
      return { invalidBusinessName: true };
    }
  }

  alphaNumValid(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ALPHA_NUM)) {
      return null;
    } else {
      return { invalidAlphaNumeric: true };
    }
  }

  alphaNumericValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ALPHA_NUMERIC)) {
      return null;
    } else {
      return { invalidAlphaNumeric: true };
    }
  }

  alphaNumericContactValidator(control:any){
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ALPHA_NUMERIC)) {
      return null;
    } else {
      return { invalidAlphaNumericContact: true };
    }
  }

  alphaValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ALPHA_MIN_MAX)) {
      return null;
    } else {
      return { invalidAlpha: true };
    }
  }

  alphaValidatorCustomer(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ALPHA_MIN_MAX)) {
      return null;
    } else {
      return { invalidAlphaCustomerName: true };
    }
  }

  alphaNumValidator(control:any) {
    let val = control.value?.toString().trim();
    if (control.value && val.match(Constants.REGEX.ALPHA_NUMERIC_SPECIAL_CHAR)) {
      return null;
    } else {
      return { invalidAlpha: true };
    }
  }

  linkValidator(control:any) {
    let val = control.value?.toString().toLowerCase().trim();
    if (val && val.match(Constants.REGEX.LINK)) {
      return null;
    } else {
      return { invalidLink: true };
    }
  }

  alphaNumericSpecialValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ALPHA_NUMERIC_SPECIAL_CHAR_WITH_SPACE)) {
      return null;
    } else {
      return { invalidAlphaSpecial: true };
    }
  }

  addressValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ACCEPT_MULTI_LANGUAGE)) {
      return null;
    } else {
      return { invalidAddress: true };
    }
  }
  
  billingAddressValidator(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.ACCEPT_MULTI_LANGUAGE)) {
      return null;
    } else {
      return { invalidBillingAddress: true };
    }
  }

  yearNumber(control:any) {
    let date = new Date().getFullYear();
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.YEAR) && parseInt(control.value) <= date) {
      return null;
    } else {
      return { invalidYearNumber: true };
    }
  }

  pincodeNumber(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.PINCODE)) {
      return null;
    } else {
      return { invalidPincodeNumber: true };
    }
  }

  gstNumber(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.GST)) {
      return null;
    } else {
      return { invalidGSTNumber: true };
    }
  }

  panNumber(control:any) {
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.PAN)) {
      return null;
    } else {
      return { invalidPANNumber: true };
    }
  }

  emailOrNumber(control:any){
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.EMAIL_MOBILE)) {
      return null;
    } else {
      return { invalidEmailOrNumber: true };
    }
  }

  disposableEmail(control:any){
    const domainList : string[] = environment.disposableDomainList;
    if (control.value?.toString() && !domainList.includes(control.value.split('@')[1])) {
      return null;
    } else {
      return { disposableEmail: true };
    }
  }

  businessSize(control:any){
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.DIGITS)) {
      return null;
    } else {
      return { invalidBusinessSize: true };
    }
  }

  cityValidator(control:any){
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.DIGITS)) {
      return null;
    } else {
      return { invalidCity: true };
    }
  }

  industryValidator(control:any){
    let val = control.value?.toString().trim();
    if (val && val.match(Constants.REGEX.DIGITS)) {
      return null;
    } else {
      return { invalidIndustry: true };
    }
  }
  responseLimit(control:any) {
    let val = control.value?.toString().trim();
    if (val && parseInt(val) > 0) {
      return null;
    } else {
      return { invalidLimit: true };
    }
  }

  getStringFromArray(str:any){
    return str.join(', ').replace(/,(?!.*,)/gmi, ' and')
  }

  AcceptValidator(accept: string[]): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      if (!accept) {
        throw ('AcceptValidator: allowable type of file can not be empty');
      }
      if (ctrl.value == null) return null;
      if (!accept.some(val => ctrl.value?.type?.includes(val))) {
        return {
          accept: this.translate.instant('STRING_CONST.invalid_file',{ext: this.getStringFromArray(accept)})
        };
      }
      return null;
    }
  }

  MaxSizeValidator(max: number): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      max = Number(max);
      if (isNaN(max)) {
        throw 'MaxSizeValidator: max of size of file is invalid';
      }
      if (!ctrl.value) return null;
      let files: File[] = ctrl.value;
      if (!Array.isArray(ctrl.value)) {
        files = [ctrl.value];
      }
      let fileName = files[0].name;
      var extension = fileName?.substring(fileName?.lastIndexOf('.') + 1).toString().toLowerCase();
      if (files[0]?.name?.length > 10) {
        fileName = fileName?.substring(0, 10) + '...' + extension;
      }
      if (!files.length) return null;
      const add = (a: any, b: any): number => a + b;
      const sumSize = files.map(x => x.size).reduce(add);
      if (sumSize > max) {
        return {
          maxSize: this.translate.instant('STRING_CONST.file_more_size',{name: fileName,size: this.formatBytes(max)})
        };
      }
      return null;
    }
  }

  formatBytes(bytes: any, decimalPoint = 2) {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
      dm = decimalPoint,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  setMaxValue(field:string,text :any,max:number,form:UntypedFormGroup){
    if(text.length > max) {
      form.get(field)?.setValue(text.substring(0, max));
    }
  }

  getErrorMessage(formControl:UntypedFormControl | AbstractControl) {
    if (formControl.hasError('invalidBusinessName')) {
      return this.translate.instant('ERROR_MSG.INVALID_BUSINESS_NAME');
    }
    if (formControl.hasError('invalidAlphaNumeric')) {
      return this.translate.instant('ERROR_MSG.INVALID_BUSINESS_NAME');
    }
    if (formControl.hasError('invalidAlphaNumericContact')) {
      return this.translate.instant('ERROR_MSG.INVALID_CONTACT_NAME');
    }
    if (formControl.hasError('invalidAlpha')) {
      return this.translate.instant('ERROR_MSG.INVALID_NAME');
    }
    if (formControl.hasError('invalidAlphaCustomerName')) {
      return this.translate.instant('ERROR_MSG.INVALID_CUS_NAME');
    }
    if (formControl.hasError('invalidAlphaSpecial')) {
      return this.translate.instant('ERROR_MSG.INVALID_CALL_TITLE');
    }
    if (formControl.hasError('invalidGSTNumber')) {
      return this.translate.instant('ERROR_MSG.INVALID_GST');
    }
    if (formControl.hasError('invalidPANNumber')) {
      return this.translate.instant('ERROR_MSG.INVALID_PAN');
    }
    if (formControl.hasError('invalidYearNumber')) {
      return this.translate.instant('ERROR_MSG.INVALID_YEAR_ESTABLISHMENT');
    }
    if (formControl.hasError('invalidPincodeNumber')) {
      return this.translate.instant('ERROR_MSG.INVALID_PINCODE');
    }
    if (formControl.hasError('invalidAddress')) {
      return this.translate.instant('ERROR_MSG.INVALID_ADDRESS');
    }
    if (formControl.hasError('invalidBillingAddress')) {
      return this.translate.instant('ERROR_MSG.INVALID_BILLING_ADDRESS');
    }
    if (formControl.hasError('invalidEmailAddress')) {
      return this.translate.instant('ERROR_MSG.INVALID_EMAIL');
    }
    if (formControl.hasError('invalidMobileNum')) {
      return this.translate.instant('ERROR_MSG.INVALID_MOBILE');
    }
    if (formControl.hasError('invalidMobileListNum')) {
      return this.translate.instant('ERROR_MSG.INVALID_MOBILES');
    }
    if(formControl.hasError('disposableEmail')){
      return this.translate.instant('ERROR_MSG.INVALID_EMAIL_MOBILE');
    }
    if(formControl.hasError('invalidLink')){
      return this.translate.instant('ERROR_MSG.INVALID_LINK');
    }
    if (formControl.hasError('invalidBusinessSize')) {
      return this.translate.instant('ERROR_MSG.INVALID_BUSINESS_SIZE');
    }
    if (formControl.hasError('invalidCity')) {
      return this.translate.instant('ERROR_MSG.INVALID_CITY');
    }
    if (formControl.hasError('invalidIndustry')) {
      return this.translate.instant('ERROR_MSG.INVALID_INDUSTRY');
    }
    if (formControl.hasError('invalidLimit')) {
      return this.translate.instant('ERROR_MSG.INVALID_LIMIT');
    }
    if (formControl.hasError('serverError')) {
      return formControl.errors?.serverError;
    }
    return formControl.hasError('invalidEmailOrNumber') ? this.translate.instant('ERROR_MSG.INVALID_EMAIL_MOBILE') : '';
  }
}
