import { Injectable } from '@angular/core';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../utility/util.service';
import { Constants } from '../../constants';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {  
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private util:UtilService
  ) { }

  saveLanguage(language:any){
    localStorage.setItem('megrow_language',language)
  }

  getLanguage(){
    return localStorage.getItem('megrow_language') || 'en';
  }

  gotoParticipation(link:any){
    let langId = Constants.LANGUAGE_LIST.find(lang => lang.shortCode == this.translate.getDefaultLang())?.id
    window.location.href = link + (langId ? "&language_id="+langId : '');
  }

  setLanguage(language:any){
    this.translate.setDefaultLang(language)
    this.translate.use(language);
  }

  getChangeNumberOrEmailText(val:any){
    return /^\d+$/.test(val) ? this.transTxt('auth.change_number') : this.transTxt('auth.change_email');
  }

  getGenderListWithAll(){
    return [{id:'false',name: this.transTxt('pi.all')},{id:1,name:this.transTxt('pi.male')},{id:2,name:this.transTxt('pi.female')},{id:5,name:this.transTxt('pi.others')},{id:3,name:this.transTxt('pi.NA')},
    {id:4,name:this.transTxt('pi.donot_want_to_comment')}];
  }

  getGenderList(){
    return [{id:1,name:this.transTxt('pi.male')},{id:2,name:this.transTxt('pi.female')},{id:5,name:this.transTxt('pi.others')},{id:3,name:this.transTxt('pi.NA')},
    {id:4,name:this.transTxt('pi.donot_want_to_comment')}];
  }

  getAgeGroupList() {
    return [
      {id:'0',name:this.transTxt('pi.all')},
      {id:1,name:'14 - 18'},
      {id:2,name:'19 - 25'},
      {id:3,name:'26 - 30'},
      {id:4,name:'31 - 35'},
      {id:5,name:'36 - 40'},
      {id:6,name:'41 - 50'},
      {id:7,name:'51 - 60'},
      {id:8,name:'60+'},
    ]
  }

  getNpsRatingList(){
    return [
      {id:1, name: this.transTxt('insights_lab.promoter')},
      {id:2, name:this.transTxt('insights_lab.detractor')},
      {id:3, name:this.transTxt('insights_lab.passive')},
    ]
  }

  getNpsList(){
    return [
      {id:3, name: this.transTxt('insights_lab.promoter')},
      {id:1, name:this.transTxt('insights_lab.detractor')},
      {id:2, name:this.transTxt('insights_lab.passive')},
    ]
  }

  getStarRatingList(){
    return [
      {id:1, name: this.transTxt('insights_lab.poor_star')},
      {id:2, name: this.transTxt('insights_lab.neutral_star')},
      {id:3, name: this.transTxt('insights_lab.good_star')},
    ]
  }

  getBusinessSize(){
    return [
      {name:this.transTxt('STRING_CONST.one_emp'),id:1},
      {name:this.transTxt('STRING_CONST.2_emp'),id:2},
      {name:this.transTxt('STRING_CONST.6_emp'),id:3},
      {name:this.transTxt('STRING_CONST.more_10_emp'),id:4},
    ]
  }

  transTxt(key:any,param?:any){
    return this.translate.instant(key,param);
  }

  openSnackBar(message:string = this.transTxt('ERROR_MSG.RETRY'), action:string = this.transTxt('STRING_CONST.TOAST_BTN_NAME')) {
    this.util.openSnackBar(message,action);
  }
  
  isFileValid(files: any,supportedExt:string[],supportedsize:number) {
    const file = files[0];
    var size = file.size;
    var name = file.name;
    var extension = name.substring(name.lastIndexOf('.') + 1).toString().toLowerCase();
    if (supportedExt.includes(extension)) {
      let finalName = name;
      if (name.length > 20) {
        finalName = name?.substring(0, 20) + '...' + extension;
      }
      if (size > supportedsize) {
        this.util.openSnackBar(this.transTxt('STRING_CONST.file_more_size',{name: finalName,size: this.util.formatBytes(supportedsize)}),this.transTxt('subscribe_modal.dismiss'))
        return false
      } else if (size <= 0) {
        this.util.openSnackBar(this.transTxt('STRING_CONST.file_not_empty',{name: finalName}),this.transTxt('subscribe_modal.dismiss'))
        return false
      } else {
        return true
      }
    } else {
      this.util.openSnackBar(this.transTxt('STRING_CONST.invalid_file',{ext: this.util.getStringFromArray(supportedExt)}),this.transTxt('subscribe_modal.dismiss'))
      return false
    }
  }

  confirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data,
      width: '400px',
      disableClose: true,
    }).afterClosed();
  }

  viewOfferDialog(content: string) {
    this.confirmDialog({
      title: this.transTxt('automation.offer_message'),
      message: content,
      template: '<p class="primary offer-route">'+this.transTxt('gt.update_automation_path')+'</p>',
      confirmCaption: '',
      cancelCaption: this.transTxt('my_qrcode.close'),
    }).subscribe((yes) => { });
  }

  infoOfferDialog(message:string) {
    this.confirmDialog({
      title: '',
      message: message,
      confirmCaption: '',
      cancelCaption: this.transTxt('my_qrcode.close'),
    }).subscribe((yes) => { });
  }

  featureNotAvailableDialog() {
    this.confirmDialog({
      title: '',
      message: this.transTxt('customer_engage.whatsapp_feature_not_available'),
      template: '<p class="primary offer-route">'+ this.transTxt('customer_engage.whatsapp_customer_engagement')+'</p>',
      confirmCaption: '',
      cancelCaption: this.transTxt('insights_lab.okay'),
    }).subscribe((yes) => { });
  }

  calculateAge(form: UntypedFormGroup) {
    let birthday = form.get('dob')?.value;
    if (!birthday) {
      form.get('dob')?.setErrors({ invalidAge: true });
      return true;
    }
    let birthDate = new Date(birthday);
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();
    let dayDifference = today.getDate() - birthDate.getDate();
    
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    
    if (age < 14) {
      form.get('dob')?.setErrors({ invalidAge: true });
      return true;
    } else {
      form.get('dob')?.setErrors(null);
      form.get('dob')?.updateValueAndValidity();
      return false;
    }
  }

  isPlanDisabled(plans: any, activePlanId: number): boolean {
    return plans.includes(activePlanId);
  }
}
