import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, fromEvent } from 'rxjs';
// declare var Fingerprint2: any;
import * as Fingerprint2 from 'fingerprintjs2'
import { ConfirmDialogComponent, ConfirmDialogData } from '../../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Location, LocationStrategy } from '@angular/common';
import { CustomSnackbarComponent } from '../../components/custom-snackbar/custom-snackbar.component';
import { Constants } from '../../constants';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  browserFingerprint: string = '';
  hasFingerPrint: any;
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  
  public previousUrl: ReplaySubject<string> = new ReplaySubject<string>();
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  public previousUrlData:string = '';
  
  existingSurveyData:any[] = [];
  choosePlan = '';
  paymentFailedObj:any = {};
  welcomeData:any = {}
  whatsappStatusData:any
  private subscribtionBackButton!:Subscription;
  constructor(
    private router:Router,
    private _location:Location,
    private _snackBar:MatSnackBar,
    private locationStrategy:LocationStrategy,
    private dialog: MatDialog
  ) { 
    this.getBrowserFingerPrint();
  }

  loadScript():HTMLScriptElement {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = 'google-api';
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+environment.googleApiKey+'&libraries=places&sensor=false';
    document.body.appendChild(script);
    return script;
  }

  loadRazorPayScript():HTMLScriptElement {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = 'razorpay-api';
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.body.appendChild(script);
    return script;
  }

  getBrowserFingerPrint() {
    return new Promise((resolve, reject) => {
      Fingerprint2.get((components:any) => {
        var values = components.map((component:any) => { return component.value })
        var hash = Fingerprint2.x64hash128(values.join(''), 31);
        this.browserFingerprint = hash;
        resolve(this.browserFingerprint);
      })
    });
  }

  ////Existing survey data

  setExistSurvey(data: any[]) {
    this.existingSurveyData = data;
  }
  
  getExistSurvey() {
    return this.existingSurveyData;
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrlData = previousUrl;
    this.previousUrl.next(previousUrl);
  }
  
  removeNullVariable(obj:any = {}){
    Object.keys(obj).forEach((k) => {
      if(obj[k] == 'null' || obj[k] == null|| obj[k] == ''
       || obj[k] == undefined || obj[k] == 'NaN-NaN-NaN'){
        delete obj[k]
      }
    });
  }

  downloadFile(invalidNumbers:any[]) {
    let newArray:any[] = [];
    newArray.push('Invalid Numbers')
    invalidNumbers.forEach((element:any) => {
      newArray.push(element)
    });
    const csvArray = newArray.join('\r\n');
    console.log(csvArray)
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'ErrorFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  openCustomSnackbar(message:string, duration = 10000) {
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        html: message
      },
      duration: duration
    });
  }

  getChangeNumberOrEmailText(val:any){
    return /^\d+$/.test(val) ? 'Change number' : 'Change email';
  }

  ////////////
  setOTPTimer(time: string,key:string = 'time') {
    if(time){
      localStorage.setItem(key, time);
    }
  }

  getOTPTimer() {
    return parseInt(localStorage.getItem('time') || '120');
  }

  removeOTPTimer() {
    return localStorage.removeItem('time');
  }

  /******** Userame*****************/
  setUsername(username: string) {
    if(username){
      localStorage.setItem('username', username);
    }
  }

  getUsername() {
    return localStorage.getItem("username") || '';
  }

  removeUsername() {
    return localStorage.removeItem("username");
  }

  setStoreId(data: string) {
    if(data){
      localStorage.setItem('store_id', data);
    }
  }

  getStoreId() {
    return localStorage.getItem("store_id");
  }

  /***************store details ************/
  setStoreDetails(data: any) {
    if(data){
      let roleId = this.getStoreDetails()?.roleId
      if(roleId){
        data.roleId = roleId;
      }
      localStorage.setItem('store_details', JSON.stringify(data));
    }
  }

  getStoreDetails() {
    return JSON.parse(localStorage.getItem("store_details") || '{}');
  }
  /////////subs details/////
  setSubs(data: any) {
    localStorage.setItem('me_grow_subs', JSON.stringify(data));
  }

  getSubs() {
    return JSON.parse(localStorage.getItem('me_grow_subs') || '{}');
  }
  // Choose Plan ////

  setChoosePlan(data: any) {
    localStorage.setItem('me_grow_choose_plan', JSON.stringify(data));
  }

  getChoosePlan():any {
    return JSON.parse(localStorage.getItem('me_grow_choose_plan') || '{}');
  }

  removeChoosePlan():any {
    localStorage.removeItem('me_grow_choose_plan')
  }
  /////////Plan data////////

  setPlanData(data: any) {
    localStorage.setItem('me_grow_plan', JSON.stringify(data));
  }

  getPlanData() {
    return JSON.parse(localStorage.getItem('me_grow_plan') || '{}');
  }

  ///////paymet status//////
  setPaymentStatus(payment: string) {
    if(payment){
      localStorage.setItem('me_grow_payment', payment);
    }
  }

  getPaymentStatus() {
    return localStorage.getItem("me_grow_payment") || '';
  }

  // Welcome ///////////////

  setWelcomeData(data: any) {
    this.welcomeData = data;
  }

  getWelcomeData() {
    return this.welcomeData;
  }

  setWelcome(data: any) {
    localStorage.setItem('is_welcome', data);
  }

  getWelcome() {
    return localStorage.getItem("is_welcome");
  }
  ///////// token///////////

  login(token: string) {
    localStorage.setItem('avid_plus_token', token);
  }

  getToken() {
    return localStorage.getItem("avid_plus_token");
  }

  saveUserId(user_id: string) {
    localStorage.setItem('user_id', user_id);
  }

  getUserId() {
    return localStorage.getItem('user_id') || '';
  }

  logout() {
    this.clearLocalStorage();
  }

  clearLocalStorage() {
    localStorage.clear();
    this.router.navigate(['/signin']);
    // this.router.navigate(['/signin'], { queryParams: {returnUrl: this.router.url?.split('?')[1]} });
  }

  redirectToSignin() {
    localStorage.clear();
    this.router.navigate(['/signin'], { queryParams: {returnUrl: window.location.href} });
  }

  deleteAccount() {
    localStorage.clear();
    this.router.navigate(['/signup']);
  }

  isPlanExpired(){
    let planData = this.getPlanData();
    let subs = this.getSubs();
    let isPlanExpired = !this.isAfter(subs.endDate)
    if(isPlanExpired && !planData?.show_plan_extension) {
	return true;
    } else {
      return false;
    }
  }

  checkPlanValidatity(){
    let planData = this.getPlanData();
    let subs = this.getSubs();
    let feature = JSON.parse(subs.features || '{}');
    let isPlanExpired = !this.isAfter(subs.endDate)
    let isLimitReached = planData?.total_respondent_count >= feature.no_of_responses;
    if(isPlanExpired && isLimitReached){
      return true;
    } else if(isPlanExpired && !planData?.show_plan_extension) {
      return true;
    } else {
      return false;
    }
  }

  handleStatusErrorCode(response:any,form:any = {}){
    if (response.success === false) {
      if (response.status_code === 422) {
        if(Object.keys(form).length != 0){
          for (const [key, value] of Object.entries(response.data)) {
            let val:any[] = value as []
            form?.controls[key]?.setErrors({
              serverError: val[0]
            });
          }
        }
        this.openSnackBar(response.message,Constants.STRING_CONST.TOAST_BTN_NAME)
      } else if (response.status_code === 401 || response.status_code === 1611 || response.status_code === 1612) {
        this.openSnackBar(response.message, Constants.STRING_CONST.TOAST_BTN_NAME)
      } else if(response.status_code === 1651){
        this.router.navigateByUrl('/calls');
      } else if(response.status_code === 1014 || response.status_code === 1086){
        this.openSnackBar(response.message,Constants.STRING_CONST.TOAST_BTN_NAME)
        this.clearLocalStorage()
        this.router.navigateByUrl('/signin');
      } else {
        console.log(response)
        this.openSnackBar(response.message,Constants.STRING_CONST.TOAST_BTN_NAME)
      }
    }
  }

  confirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data,
        width: '400px',
        disableClose: true,
      })
      .afterClosed();
  }

  preventBackButton() {
    history.pushState(null, 'null', location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, 'null', location.href);
    })
  }

  preventBack() {
    window.history.forward();
  }

  disableBackButton() {
    history.pushState(null, 'null', location.href);
    this.subscribtionBackButton = fromEvent(window, 'popstate').subscribe((_) => {
      history.pushState(null, 'null', location.href);
    });
  }

  disableBackButtonFirefox(orderId:any){
    if(navigator.userAgent.toLowerCase().indexOf('firefox') !== -1){
      history.pushState('', '', location.origin+'/invoice-summary/'+ orderId);
      this.locationStrategy.onPopState(() => {
        history.pushState('', '', location.origin+'/invoice-summary/'+ orderId);
        location.reload()
      })
    }
  }

  unsubscriberBackButton(): void {
    if(this.subscribtionBackButton){
      this.subscribtionBackButton.unsubscribe();
    }
  }

  isAfterDateTime(data:any, endDate:any){
    const eDate = this.formatDateUTCToLocalTime(endDate)
    let selectedDate = new Date(data.date)
    selectedDate.setHours(data.start_time.split(':')[0]);
    selectedDate.setMinutes(data.start_time.split(':')[1]);
    return eDate.getTime() < selectedDate.getTime();            
  }

  isSelectedDateGreater(data:any){
    /* we can write better and small code for this 
    but in order to work in iphone 6 and below code is necessary */
    let currentDate = new Date();
    let year = new Date(data.date).getFullYear();
    let month = new Date(data.date).getMonth();
    let date = new Date(data.date).getDate();
    let hour = data.start_time.split(':')[0];
    let minute = data.start_time.split(':')[1];
    let selectedDate = new Date(year,month,date,hour,minute)
    return selectedDate.getTime() < currentDate.getTime();
  }

  formatDateUTCToLocalDate(dateData:any):Date{
    let date = new Date(moment.utc(dateData).local().format('YYYY-MM-DD'))
    return date;
  }

  formatDateUTCToLocalTime(dateData:any):Date{
    /* we can write better and small code for this 
    but in order to work in iphone 6 and below code is necessary */
    let year = dateData?.split('-')[0];
    let month = dateData?.split('-')[1];
    let date = dateData?.split('-')[2].split(' ')[0];
    let hour = dateData?.split(' ')[1].split(':')[0];
    let minute = dateData?.split(':')[1];
    let selectedDate = this.convertUTCDateToLocalDate(new Date(year,month-1,date,hour,minute))
    return selectedDate;
  }

  formatDateUTC(dateData:any){
    return moment(dateData, "YYYY-MM-DD HH:mm").utc().format('yyyy-MM-DD');
  }

  formatTimeUTC(dateData:any){
    const date = new Date(dateData)
    return moment(dateData, "YYYY-MM-DD HH:mm").utc().format('HH:mm');
  }
  
  formatDateAsc(dateData:any){
    const date = new Date(dateData)
    return `${date.getFullYear()}-${this.toTwoDigit(date.getMonth()+1)}-${this.toTwoDigit(date.getDate())}`;
  }

  toTwoDigit(data:any){
    return (data).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  }

  getDateFormat(date:any,format:string){
    return moment(date).format(format);
  }

  dateUTCToLocalDateFormat(dateData:any,format:string){
     var gmtDateTime = moment.utc(dateData)
     return gmtDateTime.local().format(format);
  }

  isAfter(date:any){
    const currentDate = new Date(); 
    let m = moment.utc(date, "YYYY-MM-DD  HH:mm:ss");
    return m.isAfter(currentDate);              
  }

  isExpired(endDate:any) {
    const today = moment().startOf('day');
    endDate = moment(endDate).startOf('day');
    return endDate.isBefore(today);
  }
  
  getInitials(nameString:string){
    const fullName = nameString?.split(' ');
    const initials = fullName?.shift()?.charAt(0);
    return initials?.toUpperCase();
  }

  convertUTCDateToLocalDate(date:any) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;   
  }
  
  convertMinsToHrsMins = (mins:number) => {
    let h:any = Math.floor(mins / 60);
    let m:any = mins % 60;
     h = String(h).padStart(2, '0')
     m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  }

  addTwentyMinutesToTime(start_time:any){
    let twentyHoursIntoMinutes = 1440;
    let hour = start_time.toString().split(':')[0];
    let min = start_time.toString().split(':')[1]
    let currentMinutes = (parseInt(hour) * 60) + parseInt(min) + 20;
    let end_time = 0;
    if(currentMinutes >= twentyHoursIntoMinutes){
      end_time = currentMinutes - twentyHoursIntoMinutes;
    }else{
      end_time = currentMinutes;
    }
    return end_time
  }

  isEmailOrMobile(number:any) {
    let is_email_or_mobile;
    if(number.match(Constants.REGEX.MOBILE)){
      is_email_or_mobile = '2'
    }else {
      is_email_or_mobile = '1'
    }
    return is_email_or_mobile;
  }

  setNumberValuesUptoFour(form:any,field:string,text :any, yearNumber:any){
    return this.checkValidNumber(form, field, text, yearNumber,Constants.REGEX.MAX_FOUR_DIGIT)
  }

  setNumberValuesUptoSix(form:any,field:string,text :any, pincodeNumber:any){
    return this.checkValidNumber(form, field, text, pincodeNumber,Constants.REGEX.MAX_SIX_DIGIT)
  }

  setNumberValuesUptoTen(form:any, field:string, text :any, mobileNumber:any){
    return this.checkValidNumber(form, field, text, mobileNumber,Constants.REGEX.MAX_TEN_DIGIT)
  }

  setValuesOnlyDigits(form:any, field:string, text :any, previousVal:any){
    return this.checkValidNumber(form, field, text, previousVal,Constants.REGEX.DIGITS)
  }

  checkValidNumber(form:any, field:string, text :any, previousVal:any,regex:any){
    if(text.toString().match(regex)){
      previousVal = text
      return text;
    }else{ 
      form.get(field)?.setValue(previousVal);
      return previousVal;
    }
  }

  setValue(form:any,field:string,text:any){
    text?.toString().trim()
    form.get(field)?.setValue(text);
    form.get(field)?.updateValueAndValidity({emitEvent: false});
  }

  setValidators(form:any,field:string, validator:any[]){
    form.get(field)?.setValidators(validator);
    form.get(field)?.updateValueAndValidity({emitEvent: false});
  }
  
  removeValidators(form:any,field:string){
    form.get(field)?.clearValidators();
    form.get(field)?.updateValueAndValidity({emitEvent: false});
  }

  updateValue(form:any,field:string,text :any){
    form.get(field)?.setValue(text);
    form.get(field)?.markAsUntouched();
  }

  onClosedSetEndDate(form:any){
    let data = form.value;
    if((data.start_date && !data.end_date) || data?.end_date?.toString() == 'Invalid Date'){
      form.get('end_date')?.setValue(new Date(data.start_date))
    }
  }

  urldecode(url:any) {
    return url ? decodeURIComponent(url?.replace(/\+/g, ' ')) : '';
  }

  getTextFromClass(className:string,str:string){
    let s = str?.toString();
    let htmlObject = document.createElement('div');
    htmlObject.innerHTML = s;
    let span_Text =  htmlObject.querySelector('.'+className)?.innerHTML;
    return span_Text || '';
  }

  focusAndBlurField(field:string) {
    let fieldControl = document.querySelector('[formcontrolname="' + field + '"]') as HTMLElement;
    fieldControl?.focus();
    fieldControl?.blur();
  }

  downLoadFile(data: any, type: string, invoiceNumber:any) {
    const byteArray = new Uint8Array(atob(data.replace(/"/g, '')).split('').map(char => char.charCodeAt(0)));
    let blob = new Blob([byteArray], { type: type});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = invoiceNumber;
    a.click();
  }

  downloadFileFromLink(url:string){
    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
  }
  downloadPDFFile(data: any, type: string, businessName:string) {
    const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
    let blob = new Blob([byteArray], { type: type});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = businessName;
    a.click();
  }

  getStringFromArray(str:any){
    return str.join(', ').replace(/,(?!.*,)/gmi, ' and')
  }

  formatBytes(bytes: any, decimalPoint = 2) {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
      dm = decimalPoint,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  goBackToPreviousPage(){
    this._location.back();  
  }
  
  getLanguage(){
    return localStorage.getItem('megrow_language') || 'en';
  }

  getPILanguage(){
    return localStorage.getItem('megrow_survey_language') || 'en';
  }
}
