import { Component, OnInit, Inject } from '@angular/core';
import { Constants } from '../../constants';
import { UtilService } from '../../services/utility/util.service';
import { PagesService } from 'src/app/pages/pages.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
  baseQrUrl = environment.apiUrl+ '/survey/pi?s=';
  store_id : string = '';
  businessName:string = '';

  constructor(
    public dialogRef: MatDialogRef<QrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pagesService: PagesService,
    public util: UtilService,
    private common: CommonService
  ) { }

  ngOnInit(): void {
    this.store_id = this.util.getStoreDetails()?.store_id;
    this.businessName = this.data.businessName;
  }

  qrPdfGenerate(){
    let canvas =  document.getElementById("qrCode")?.children[0] as HTMLCanvasElement;
    let src = canvas?.toDataURL();
    let data = {
      image:src,
      businessName:this.businessName
    };
    this.util.show();
    this.pagesService.generatePdf(data).subscribe(
      (response: any) => {
        if (response.success === true) {
          this.downLoadFile(response.data.pdf, "application/pdf")
        } else {
          this.util.handleStatusErrorCode(response)
        }
        this.util.hide();     
      },
      (error) => {
        this.util.hide();
        this.common.openSnackBar()
      }
    );
  }

  downLoadFile(data: any, type: string) {
    const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
    let blob = new Blob([byteArray], { type: type});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = this.data?.businessName;
    a.click();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
