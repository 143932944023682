<mat-dialog-content>
    <div>
        <h2 class="text-center"><b>{{'insights_lab.nps_score'|translate}}</b></h2>
        <div class="nps-guidelines-section">
            <div class="nps-guidelines mr-2">
                <div>{{'insights_lab.below_20'| translate}}</div>
                <div>21-39</div>
                <div>40-59</div>
                <div>60-79</div>
                <div>80+</div>
            </div>
            <div class="nps-guidelines">
                <div class="red">{{'insights_lab.very_poor'|translate}}</div>
                <div class="red">{{'insights_lab.poor'|translate}}</div>
                <div class="orange">{{'insights_lab.okay'|translate}}</div>
                <div class="orange">{{'insights_lab.good'|translate}}</div>
                <div class="green">{{'insights_lab.excellent'|translate}}</div>
            </div>
        </div>
        <div class="mt-2">
            <b>{{'insights_lab.detractor'|translate}}:</b> {{'insights_lab.detractor_description'|translate}} 
        </div>
        <div class="mt-2">
            <b>{{'insights_lab.passive'|translate}}:</b> {{'insights_lab.passive_description'|translate}} 
        </div>
        <div class="mt-2">
            <b>{{'insights_lab.promoter'|translate}}:</b> {{'insights_lab.promoter_description'|translate}} 
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close cdkFocusInitial>{{'insights_lab.okay'|translate}}</button>
</mat-dialog-actions>