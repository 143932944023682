import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuard } from '../../guards/guard/auth.guard';
import { UtilService } from '../utility/util.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public auth: AuthGuard,
    private util:UtilService
    ) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.util.getToken();
    let deviceId = this.util.browserFingerprint; 
    let language = this.router.url.includes('/survey/') ? this.util.getPILanguage() : this.util.getLanguage();  
    const skipIntercept = request.headers.has('skip');

    if (skipIntercept) {
        request = request.clone({
            headers: request.headers.delete('skip')
        });
      return next.handle(request);
    }

    if (token) {
      request = request.clone({
        setHeaders: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        }
      });
    }
    if (deviceId) {
      request = request.clone({
        setHeaders: {
          "device-id": `${deviceId}`
        }
      });
    }
    if (language) {
      request = request.clone({
        setHeaders: {
          "language": `${language}`
        }
      });
    }
    return next.handle(request);
  }
}
