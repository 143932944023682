import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PiService {

  apiUrl = environment.apiUrl;
  customerDetails:any;

  constructor(
    private locationStrategy:LocationStrategy,
    private httpClient:HttpClient,
  ) { }

  // getIndustryList() {
  //   return this.httpClient.post(`${this.apiUrl}/store/get-industary`, null);
  // }

  storePi(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/store-pi`, data);
  }

  storeMobile(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/store-mobile`, data);
  }

  sendOtp(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/send-otp`, data);
  }

  verifyOtp(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/verify-otp`, data);
  }

  checkParticipation(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/check-participation`, data);
  }

  preventBackButton() {
    history.pushState(null, 'null', location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, 'null', location.href);
      console.log(location.href)
    })
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
  }

  deleteCookie(name:any) {
    this.setCookie(name, '', -1);
  }

  setCookie(name: string, value: any, expireDays: number, path: string = '/') {
    let d:Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;
    let cpath:string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath};`;
  }

  saveLanguage(language:any){
    localStorage.setItem('megrow_survey_language',language)
  }

  getLanguage(){
    return localStorage.getItem('megrow_survey_language') || 'en';
  }

}
