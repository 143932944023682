<mat-dialog-content>
    <div class="main tc-popup-section">
        <h1 style=" text-align:center;">
            TERMS AND CONDITIONS OF ADL
            <br>
        </h1>
        <div>
            <span style="font-weight: 400;">
                <b>A.</b>
                Avidestal Technologies Private Limited, a company registered under Companies Act, 2013, having its registered address at No. 1873/38, 5th Main, Ganapati Temple Road RPC Layout, Vijayanagar, Bangalore, Karnataka, 560040, India ("<b>ADL</b>") has set out the terms and conditions in this agreement 
               ("<b>T&C Agreement</b>")
                 that is applicable on:
            </span>
            <ol>
                <li>the use of the mobile application provided by ADL (the "<b>Application</b>");</li>
                <li>any service/product procured from ADL by a Subscriber (as defined hereinafter) either through the Application or the Website;</li>
                <li>any other related agreement or legal relationship between ADL and Subscriber.</li>
            </ol>
            <span style="font-weight: 400;">
                <b>B.</b>
                This T&C Agreement shall be legally binding on all Subscribers who utilize the Services (as defined hereinafter), or other registered members or partners.
            </span>
        </div>
        <p>
            <span style="font-weight: 400;">
                <b>C.  DEFINITIONS</b>
                <br>
                Unless the context otherwise requires, or unless defined in the body of the T&C Agreement, the capitalized words and phrases used in this T&C Agreement shall have the meaning ascribed to such words and phrases in this Clause.
            </span>
        </p>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Account"</b>
                    shall mean the account created by the Subscriber on the Application or on the Website, for the purposes of procuring the Services offered by ADL, in accordance with Clause E (4) (a) of this T&C Agreement;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Applicable Law"</b>
                    means the national, state, provincial, and local: (i) laws, ordinances, regulations, and codes; and (ii) orders, requirements, directives, decrees, decisions, judgments, interpretive letters, guidance notes, notifications, and other official releases of any regulator that are applicable to ADL, its affiliates, the Services, the Subscribers, or any other matters relating to the subject matter of this T&C Agreement;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Application"</b>
                    means Me-grow application developed by ADL and shall include any other application which operates as a customer interface and is linked in any manner to the Me-grow application;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Business(es)"</b>
                    shall mean and include the various business entities across sectors;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Cancellation Fee"</b>
                    shall mean the fee payable by the Subscriber, for cancellation of Services already procured by such Subscriber;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Consumer"</b>
                    shall mean and refer to the end-consumer of the Subscribers, who submit their feedback through the Growth Template;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Growth Template"</b>
                    shall mean the templates developed by ADL for the Subscribers, custom questionnaires created by Subscribers or ADL and/or surveys that are to be sent from the Application, through which responses can be obtained by the Subscribers from its Consumers;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Privacy Policy"</b>
                    shall mean the privacy policy of ADL as accessible from the Application or Website of ADL at <a
                        target="_blank" href="">https://me-grow.com/privacy-policy</a>.
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Service(s)"</b>
                    shall mean the consumer-analytics consultancy services provided to the Subscriber through which ADL will obtain responses from the Consumers in various Growth Templates and prepare Reports to be accessed by the Subscribers, and shall include any Add-ons provided by ADL;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Subscription Fee"</b>
                    shall mean the fee payable by the Subscriber for availing the Services from ADL by registering for a Subscription;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Subscription"</b>
                    shall mean the subscription plan provided by ADL, for procurement of Services in various structured plans;
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Subscriber(s)"</b>
                    shall mean the subscriber procuring the Services by registering for a Subscription through the Application or the Website and shall include:
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) Any entity including companies, partnership firms, LLPs, sole proprietors owning, and operating a Business;
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) Entities owning and operating Businesses through a franchise model; and
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(c) any other person undertaking the Business;
                    <br>
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); border-bottom: none; padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Online Engagement(s)"</b>
                    shall mean engaging in online discussions, forming online communities and forums, or in any other manner through which the Subscriber and Consumers can interact with each other on the Application; and
                </span>
            </p>
        </div>
        <div style="border: 1px solid rgb(117, 117, 117); padding: 10px;">
            <p>
                <span style="font-weight: 400;">
                    <b>"Website"</b>
                    shall mean the website for the Application, or any other website developed by ADL.
                </span>
            </p>
        </div>
        <br>
        <p>
            <span style="font-weight: 400;">
                <b>
                    D. ACCEPTANCE OF THE T&C AGREEMENT
                </b>
                <ol>
                    <li>
                        By installing, operating, or using the Application, or on accessing the Website or procuring the Services, the Subscriber acknowledges and agrees to the conditions specified in this T&C Agreement.
                    </li>
                    <li>
                        Unless otherwise specified in this T&C Agreement, the T&C Agreement is applicable to procurement of Services, including but not limited to the supportive services and information posted on the Application or the Website.
                    </li>
                    <li>By accepting the terms of the T&C Agreement, the Subscriber, also allows ADL to send promotional alerts and other advertisements through the Application or the Website, or on their registered e-mail address or phone number.</li>
                    <li>The agreement of the Subscriber to this T&C Agreement shall operate as a binding agreement between ADL and the Subscriber, in respect of the access and usage of the Application, the Website, and procurement of any Services offered by ADL.</li>
                    <li>ADL may specify additional or supplemental terms, that are applicable to the Services or any part of it, and such terms shall be disclosed to the Subscriber, through an amendment to the T&C Agreement or through any document issued directly to the Subscriber, containing such supplemental terms. Such supplemental terms shall be deemed to be applicable to the Subscriber, in addition to the terms under this T&C Agreement.</li>
                    <li>By using this Application or procuring Services through the Website, the Subscribers shall:</li>
                    <ul style="list-style: none;">
                        <li>a. qualify as consumers, in accordance with the Applicable Law;</li>
                        <li>b. be competent to enter into a contract under the Applicable Law;</li>
                        <li>c. have carefully gone through and have complete knowledge of the terms of the T&C Agreement;
                        </li>
                        <li>d. be in compliance with the Privacy Policy of ADL; and</li>
                        <li>e. not be affiliated to or be part of any organization named in the list of banned organizations as maintained by the Government of India.</li>
                    </ul>
                </ol>
            </span>
        </p>
        <p>
            <span style="font-weight: 400; ">
                <b>E. TERMS OF USE OF APPLICATION AND THE WEBSITE</b><br /><br />
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. CONTENT OF THE APPLICATION AND THE WEBSITE</b>
                <ol style="list-style: none;">
                    <li>
                        a. This Application and the Website are provided by:
                        <b><br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Avidestal Technologies Private Limited
                        <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bengaluru, India
                        <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contact email</b>: support@me-grow.com
                    </li>
                    <li>
                        b. Unless otherwise specified or clearly recognizable, all content available on the Application or the Website is owned or provided by ADL or its licensors.
                    <li>
                        c. The Application and the Website has been provided to facilitate the procurement of Services by the Subscribers and to make payments for the Services in accordance with this T&C Agreement.</li>
                </ol>
            </span>
        </p>
        <div>
            <span style="font-weight: 400; ">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. SOFTWARE LICENSE</b>
            </span>
            <ol style="font-weight: 400; list-style: none; ">
                <li>
                    a. Any Intellectual Property Right, and any other exclusive rights on software or technical applications embedded in or related to the Application or its content, or the Website are held by ADL and/or its licensors.
                </li>
                <li>
                    b. Subject to Subscribers' compliance with and notwithstanding any divergent provision of this T&C Agreement, ADL merely grants Subscribers a revocable, non-exclusive, non-sublicensable and non-transferable license to use the Application or the Website within the scope and for the purposes of procuring the Service offered <b>("License").</b>
                </li>
                <li>
                    c. The License does not grant Subscribers any rights to access, usage, or disclosure of the original source code. All techniques, algorithms, and procedures contained in the software and any documentation related thereto, is ADL's or its licensors' sole property.
                </li>
                <li>d. All rights and the License granted to the Subscribers shall immediately terminate upon the termination or expiration of this T&C Agreement.</li>
                <li>e. Without prejudice to the above, under this License, Subscribers are eligible to download, install, use, and run only one instance of the Application, provided that such device is up to date in terms of technology.</li>
                <li>
                    f. Subscribers shall not copy, share, modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer, or assign to third parties or create derivative works from the content available on the Application or the Website, nor allow any third-party to do so through the Subscriber or their device, even without the Subscriber's knowledge.
                </li>
                <li>
                    g. The Subscriber agrees not to use any device, software, or routine to interfere, or attempt to interfere with the proper working of the Application or the Website, or any transaction being conducted on the Application or the Website, or with any other person's use of the Application or the Website.
                </li>
                <li>
                    h. ADL reserves the right to release updates, fixes, and further developments of the Application, the Website and/or their related software and to provide them to the Subscribers for free, or on payment of a fee. Subscribers shall be required to download and install such updates to continue using the Application, the Website and/or their related software.
                </li>
                <li>i. The Services shall be provided, and access to the Website and the Application shall be granted, in accordance with the Privacy Policy of ADL which shall be construed as a part of this T&C Agreement.
                </li>
                <br>
                <li>j. <b>DOWNLOADING THE APPLICATION AND ACCESS TO THE WEBSITE</b></li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        i. The Subscribers may: access the Application on the Website through a third-party web browser; or download the Application through a third-party web store. To access such downloads or access the Website, Subscribers must follow the instructions provided on the relevant third-party web store or web browser, which may vary depending on the particular device in use.
                    </li>
                    <li>
                        ii. Unless otherwise specified, the act of downloading or accessing the Application through third-party web stores or web browser shall be subject to such third-party web store's or web browser's terms and conditions, which, in case of any inconsistency or conflict, shall prevail upon this T&C Agreement.
                    </li>
                    <li>
                        iii. Subscribers downloading or accessing the Application through such third-party web stores or web browser shall be in compliance with the terms and conditions of such third-party web store or web browser, as applicable, and ADL, shall not be liable for any loss or damage arising out of the download or access of Application from such third-party web store or web browser.
                    </li>
                    <li>
                        iv. Subscribers acknowledge and accept that in order to download and/or use the Application, or gain access to the Website, the intended device(s), and its respective software (including operating systems) must be legal, commonly used, up-to-date, and consistent with the current market-standards.
                    </li>
                    <li>
                        v. Although the entire contractual relationship relating to these Services is entered into solely by and between ADL and the Subscribers, the Subscribers acknowledge and agree that, where this Application has been provided to them via a third-party web store or web browser, such third-party web store or web browser, may enforce the terms in this T&C Agreement, in addition to its own terms and conditions, as a third-party beneficiary.
                    </li>
                </ol>
                <br>
                <li>k.<b>THIRD PARTY BENEFICIARIES AND ACCESS</b> </li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>i. The Application or the Website may provide Subscribers with access to other external resources provided by third parties including various advertisements and promotional pop-ups.</li>
                    <li>ii. The Application or Website may also provide the Consumer, access to a third-party review platform, through which the Consumer may also provide its review on the product/services of the Subscriber, or the Subscriber itself. Any such access of a third-party review platform shall be subject to the applicable terms and conditions of such third-party review platform and ADL shall not be, in any manner liable to the Consumer for any damages arising out of such access to the third-party review platform.</li>
                    <li>iii. The Application or the Website may also provide the Subscriber with an option to list their business, either by themselves or through the Application or the Website, on any third-party websites as selected by ADL. The Subscriber agrees that ADL shall not be liable for any such access provided to the Subscriber to the third-party website and all such access and listing is governed by the applicable terms and conditions of such third-party website(s).</li>
                    <li>iv. ADL does not represent that it has reviewed such third-party websites and is not responsible for them or any content appearing on them.</li>
                    <li>v.	Any external content or access provided by third parties shall be governed by the Applicable Law and the terms and conditions applicable to such external content or access, as provided by the relevant third-party.</li>
                    <li>vi. ADL shall not be liable to the Subscribers or the third-party for any loss or damage arising out of the usage of such external content and services, being provided by third parties.</li>
                </ol>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400; ">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. USAGE OF THE APPLICATION & WEBSITE</b>
            </span>
            <ol style="font-weight: 400; list-style: none;">
                <li>a. The Application, the Website and the Services provided by ADL shall only be used within the scope of what they are provided for, under this T&C Agreement and the Applicable Law.</li>
                <li>b. The Subscriber is solely responsible for making sure that the use of the Application, the Website, and/or the Services does not violate the Applicable Law, the T&C Agreement, or any third-party rights.</li>
                <li>c. The Subscriber shall access and use the Application or the Website to obtain information regarding the Services provided by ADL, locations, and other content and features that ADL makes available from time to time.</li>
                <li>
                    d. Certain content on the Website and the Application is for informative purposes only and does not facilitate the procurement of Services in any manner.
                </li>
                <li>
                    e. The Subscribers acknowledge and agree that, while ADL has attempted to provide accurate information on its Website and the Application, such information is subject to revision and in no event shall ADL be responsible for the accuracy, usefulness or completeness of any information, materials, or any other content on or related to the Website, or Application, nor does it warrant that any such information, materials, or other content are the most current version thereof.
                </li>
                <li>f. ADL may, from time to time, provide additional services through its Website or the Application.
                </li>
                <li>
                    g. ADL reserves the right to take appropriate measures to protect its legitimate interests including by denying the Subscriber's access to their Account, the Application, the Website or Services, terminating this T&C Agreement, reporting any misconduct performed through the Application or Services, to the competent authorities, whenever the Subscribers:
                </li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>i. violate Applicable Law and/or this T&C Agreement;</li>
                    <li>ii. infringe any third-party rights;</li>
                    <li>iii. considerably impair ADL's legitimate interests; or</li>
                    <li>iv. offend ADL or any third party.</li>
                </ol>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400; ">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. REGISTRATION AND ACCOUNT</b>
            </span>
            <ol style="font-weight: 400; list-style: none;">
                <li>a.The Subscriber acknowledges and accepts that access to the Services through the Application or the Website is granted on registration of the Subscriber and opening of an account <b>("Account").</b> To open such Account the Subscriber needs to sign up to the Application, by providing its contact details and the access code sent on such contact details. Once signed up, Subscribers may be required to provide their personal details which shall include, but not be limited to, the name of their Business, contact details, address, details of the establishment in which the Business is undertaken, GST registration details, and financial details (optional) for opening an Account <b>("Registration Data").</b> For Subscribers undertaking the Business through a franchisee model, each franchisee may be required to obtain separate Subscription by providing its Registration Data.</li>
                <li>b. Subscribers shall</li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>i. provide complete and accurate Registration Data;</li>
                    <li>ii. update the Registration Data, if and when it changes;</li>
                    <li>iii. maintain the confidentiality and security of the Registration Data and Account; and</li>
                    <li>iv. not allow any person to use their Registration Data, to gain access to the Application.</li>
                </ol>
                <li>c. The Subscriber is responsible for all activities that occur under his Account. ADL may require a Subscriber to provide proof of identity and/or proof of ownership, and proof pertaining to the right to undertake the Business, and the Subscriber agrees that ADL has a right to deny Services if such proof is not provided.</li>
                <li>
                    d. ADL reserves the right to suspend or terminate the Subscriber's Account, with immediate effect and for an indefinite period, if the Registration Data or any other data provided by the Subscriber is incorrect or false, or if the security of the Application has been compromised in any way, or for any other reason ADL may find just or equitable. 
                </li>
                <li>
                    e. If the Subscriber believes that the Account has been disabled in error, the Subscriber shall provide a written explanation, with the reasons for such error and send the same to the contact details provided in this T&C Agreement. Restoration of the Account shall be considered by ADL, based on the explanations provided by the Subscriber
                </li>
                <li>f. ADL allows the Subscriber to link its contact details to only 1 (one) Account. For sake of clarity, Subscriber can download and use the application on multiple devices and link its contact details to multiple Business establishments under one Account however, the Subscriber shall not link multiple contact details to one Account. In case of any unauthorized use of the Subscriber's Account, Subscribers should immediately contact and inform ADL.
                </li>
                <li>
                    g. ADL reserves the right to take appropriate measures to protect its legitimate interests including by denying the Subscriber's access to their Account, the Application, the Website or Services, terminating this T&C Agreement, reporting any misconduct performed through the Application or Services, to the competent authorities, whenever the Subscribers:
                </li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>i. violate Applicable Law and/or this T&C Agreement;</li>
                    <li>ii. infringe any third-party rights;</li>
                    <li>iii. considerably impair ADL's legitimate interests; or</li>
                    <li>iv. offend ADL or any third party.</li>
                </ol>
                <li>
                    c. The Subscriber is responsible for all activities that occur under his Account. ADL may require a
                    Subscriber to provide proof of identity and/or proof of ownership, and proof pertaining to the right
                    to
                    undertake the Business, and the Subscriber agrees
                    that ADL has a right to deny Services if such proof is not provided.
                </li>
                <li>
                    d. ADL reserves the right to suspend or terminate the Subscriber's Account, with immediate effect and for an indefinite period, if the Registration Data or any other data provided by the Subscriber is incorrect or false, or if the security of the Application has been compromised in any way, or for any other reason ADL may find just or equitable.
                </li>
                <li>
                    e. If the Subscriber believes that the Account has been disabled in error, the Subscriber shall provide a written explanation, with the reasons for such error and send the same to the contact details provided in this T&C Agreement. Restoration of the Account shall be considered by ADL, based on the explanations provided by the Subscriber.
                </li>
                <li>
                    f. ADL allows the Subscriber to link its contact details to only 1 (one) Account. For sake of clarity, Subscriber can download and use the application on multiple devices and link its contact details to multiple Business establishments under one Account however, the Subscriber shall not link multiple contact details to one Account. In case of any unauthorized use of the Subscriber's Account, Subscribers should immediately contact and inform ADL.
                </li>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400;  ">
                <b>F. TERMS AND CONDITIONS OF SERVICES</b>
            </span>
            <ol style="font-weight: 400; list-style: none; ">
                <b>1. SERVICES</b>
                <li>a. Notwithstanding anything mentioned elsewhere in this T&C Agreement, the Subscriber eligible to procure Services from ADL on the basis of payment of fees, shall be limited to the following:</li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>i. Subscriber located in the area currently serviceable by ADL;</li>
                    <li>ii. Subscriber who is engaged in any of the Businesses, for whom the Services are applicable; and</li>
                    <li>iii. Subscriber having a valid registration for the Business, in accordance with the terms and conditions of any brand (if applicable), and the Applicable Law.</li>
                </ol>
                <li>b. In addition to the information provided above in Clause E (4) (a), ADL may at any time, require the Subscriber to provide other information prior to procuring the Services from ADL.</li>
                <li>c. The Services shall be procured by the Subscribers and provided by ADL:</li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        i. Upon successful registration and opening of the Account and procuring a Subscription, the Subscriber shall be entitled to choose and use from the various Growth Templates available on the Application or the Website. The Subscriber may also choose to develop its own Growth Template or require ADL to customize the Growth Template, subject to payment of fee. Once such Growth Template has been submitted by the Subscriber, ADL shall have the sole and exclusive rights over such Growth Template and all responses received for such Growth Template.
                    </li>
                    <li>ii. Subsequently, the Subscriber shall be required to procure one of the Subscription plans made available by ADL and make the requisite payment for the same in accordance with Clause F (3).
                    </li>
                    <li>iii. Post registration for the Subscription, the Application will generate a quick response code <b>("QR Code"),</b> linked to the Business entity and Account selected by the Subscriber.</li>
                    <li>iv. The QR Code can be displayed at a prominent location, at the establishment of the Subscriber where the Business is being undertaken.</li>
                    <li>
                        v. The Consumers of the Subscribers may scan the QR Code and have access to the Growth Template active for the Subscriber at that moment, to share their responses on the services/product procured or intended to be procured. For such access the Consumers will be required to submit their personal data including their name, age, gender, phone number, email-id etc. The Consumer shall be entitled to submit its responses to a Growth Template, for any service/product procured or intended to be procured by the Consumer.
                    </li>
                    <li>
                        vi. The Subscriber or ADL shall be entitled to send the Growth Template to its list of Consumers, who will be granted access to the Growth Template through a link sent to their registered mobile number or e-mail. Such Consumer shall not be required to scan the QR Code to get access to the Growth Template or the Application. However, any link sent through a registered mobile number can be used only once.
                    </li>
                    <li>
                        vii. Upon successful submission of the Growth Template, and consenting to be part of the consumer panel, the Consumers may be entitled to certain rewards, in form of coupons, which can be redeemed by the Consumers in future.
                    </li>
                    <li>viii. Based on the feedback of the Consumers, the Application shall collate the data and analyze it, to host it for the Subscriber at an aggregate level <b>("Report").</b> The Subscriber may login to its Account, to view the Report in real time.</li>
                    <li>
                        ix. The Subscriber agrees that it is only entitled to access and view the Report as a part of the Services and shall not have access to any individual feedback collected by ADL from the responses submitted by the Consumers. Notwithstanding anything mentioned herein, the sharing of individual responses with the Subscriber is not an inherent part of the Services being provided by ADL.
                    </li>
                    <li>x. The Subscriber agrees that to prepare a holistic Report, the Growth Template, may contain questions on competing brands as well.</li>
                    <li>
                        xi. The Subscriber agrees that ADL holds exclusive and complete rights on collating, analyzing, and presenting the data collected from the response to Growth Templates, received from the Consumers, and may utilize such data to prepare aggregate reports for providing meaningful and insightful services to other business. ADL reserves the right to sell, share, transfer, or in any other manner, on an anonymized basis, utilize such data at its own convenience.
                    </li>
                    <li>
                        xii. ADL may, through the Application, allow the Subscribers and Consumers to participate in the Online Engagement feature of the Application. Such Online Engagement Services may or may not form part of the Subscription and may be required to be purchased by the Subscriber as an Add-on. The Subscriber agrees that ADL is just the facilitator of such Online Engagement and is not, in any manner, responsible for any exchange that may happen during such Online Engagement. All activities or events happening during an Online Engagement are the sole liability of the Subscriber and the Consumers engaged in such Online Engagement. The Subscriber further agrees that any exchange during such Online Engagement, shall not be abusive, obscene, threatening, defamatory, an invasion of privacy, or infringing intellectual property or third-party rights.
                    </li>
                    <li>
                        xiii. The Subscriber understands and agrees that the Report may contain analysis on the basis of the market study conducted on the Growth Template obtained from Consumers of other Subscribers engaged in similar Business. The data shall form a part of the Report in a general and anonymized basis and the Subscriber shall not require ADL to disclose the details of other Subscribers. Further, the Subscriber agrees and consents that the feedback obtained from its Consumers may also form part of the Report prepared for other Subscribers engaged in similar Business
                    </li>
                </ol>
                <br>
                <li>
                    d. <b>REPORTS FOR THE USERS</b>
                </li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        i. The Subscriber, may view detailed and analyzed Report, prepared by ADL, on an aggregate and anonymized level by logging in to its Account. Such Report shall be prepared solely based on the responses to the Growth Template submitted by the Consumers. 
                    </li>
                    <li>ii. The Subscriber agrees that ADL will not be required to share the personal data of the Consumers with the Subscribers. </li>
                    <li>
                        iii. The conclusions reached and views expressed in the Report are matters of opinion. The opinion of ADL is based on the responses received from the Consumers and its understanding of the Business. However, there can be no assurance that any view expressed in the Report is conclusive and the Report does not purport to offer any business solutions to the Subscriber. There may be other information pertaining to the Business not known to ADL, which may affect the contents of the Report.
                    </li>
                    <li>iv. The Report and the findings contained therein are solely for the benefit of the Subscriber and shall not be shared or relied upon by any third parties, without obtaining prior written consent of ADL.</li>
                </ol>
                <li>
                    e.  ADL may provide the Subscribers with an option to link their business profile listed on any third-party website to the QR Code and the Application. The Consumers of such Subscriber will be able to access the third-party website where the business is listed and provide their reviews, upon submitting their response to the Growth Template linked to the QR Code of the Subscriber. The Subscriber agrees, that to facilitate such linking, it consents to provide the requisite credentials for the access to the third-party website.
                </li>
                <li>
                    f.  In an event where the Subscriber does not have its business profile listed on any third-party website, the Subscriber may, upon procuring a Subscription, choose for such business profile being created and linked by ADL. On exercising such an option, the Subscriber consents to being contacted by the representatives of ADL to exchange information necessary for creation of the business profile on the third-party website. On successful linking of such third-party website to the QR Code, the Subscriber shall be entitled to change the personal data linked to such business profile hosted on the third-party website, at their convenience. 
                </li>
                <li>
                    g.  ADL represents and covenants that it shall maintain the confidentiality of all personal data provided by the Subscriber for the creation and/or linking of such business profile on third-party website.
                </li>
                <li>
                    h.  The Subscribers agree that the access to the third-party website will be governed by the terms and conditions of such third-party website and ADL shall not be liable to the Subscriber for any damages arising out of such creation, linking or access to the business profile.
                </li>
                <br>
                <b>2. OBLIGATIONS OF THE SUBSCRIBER</b>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>a. The Subscriber shall utilize the Services provided by ADL in compliance with this T&C Agreement and the Applicable Law.</li>
                    <li>b. The Subscriber shall not make any unauthorized or illegal use of the QR Code, the Application, the Website, or the Report provided by ADL. </li>
                    <li>c. The Subscriber shall prevent unauthorized access of third parties to the Account by taking appropriate precautions.</li>
                    <li>
                        d. The Subscriber agrees that the QR Code generated for procuring the Service through 1 (one) Subscription shall not be used in more than 1 (one) establishment of the Subscriber. The Subscriber shall neither transfer nor assign the Services, to third parties without obtaining prior permission from ADL.
                    </li>
                    <li>
                        e. The Subscriber shall ensure that only the Consumers that have already procured services/product from the Subscriber or show any intention of procuring any services/product from the Subscriber are allowed access to the QR Code to provide their responses to the queries in the Growth Template. 
                    </li>
                    <li>
                        f. The Subscriber agrees that separate QR Code shall be provided for each Business under one Account. The Subscriber shall be entitled to use such QR Code at only a single Business establishment. Unless otherwise permitted for by ADL, multiple Business establishments operating under a franchisee model, are required to obtain independent Subscription and QR Code for each Business establishment.
                    </li>
                    <li>
                        g. The Subscriber agrees that it shall not endeavor to manipulate the responses of Consumers and consequently the Report by generating fake and spam reviews from any person or third-party who is not a Consumer and has not procured a Service/product from such Subscriber.
                    </li>
                    <li>h. The Subscriber shall be in compliance with the confidentiality obligations set out in Clause J.</li>
                    <li>
                        i. The Subscriber shall refrain from any actions that may affect the functionality of the Services provided or arranged by ADL. This contains inter alia the use of viruses, malicious software or software that automatically generates reviews.
                    </li>
                    <li>
                        j. The Subscriber shall not change, manipulate, overwrite, copy, or distribute any part of the Application, Website, Report or the QR Code and shall be in compliance with the obligations pertaining to Intellectual Property Rights set out in Clause I.
                    </li>
                    <li>k. Subscribers shall not reproduce, duplicate, copy, sell, resell, or exploit any portion of its Services without ADL's express prior written permission, granted either directly or through a legitimate reselling program.</li>
                    <li>
                        l.	ADL may, through  the Application, provide an option for the Subscriber to review the Application. The Subscriber agrees that any review provided shall not be abusive, obscene, threatening, defamatory, an invasion of privacy, or infringing Intellectual Property or third-party rights.
                    </li>
                    <li>m. In the event of any violations of the provisions under this Clause, or illegal or suspected misuse, ADL may delete, block, or restrict the Account of the Subscriber and stop the access to the Services without prior notice.</li>
                    <li>
                        n. The Subscriber is responsible for keeping the access details to the Account, and the Report furnished by ADL, confidential and is fully responsible for all the activities that occur under your account, whether or not the Subscriber authorized such activities. The Subscriber agrees to notify ADL immediately of any breach of security or unauthorized use of the Account or the Report.
                    </li>
                </ol>
                <br>
                <b>3. PAYMENT OF FEES</b>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        a. The Subscriber shall be required to pay for the Subscription chosen. The Subscription Fee shall be inclusive of the applicable taxes.
                    </li>
                    <li>
                        b. The Subscriber may elect to procure additional Services provided by the Application, in addition to the Services provided under the Subscription <b>("Add-ons").</b> For such Add-ons procured, the Subscriber needs to pay separately, an additional fee, exclusive of taxes, that will be informed to the Subscriber while booking the Add-ons on the Application <b>("Additional Fee").</b>
                    </li>
                    <li>c. The Subscription Fee and the Additional Fee (if applicable) shall be collected by ADL through the Application, at the time for registering for such Subscription or procuring the Add-ons.</li>
                    <li>d. A receipt for the Subscription Fee and the Additional Fee (if applicable), shall be generated through the Application at the time of successful registration for a Subscription or procuring the Add-ons.</li>
                    <li>
                        e. All payments are independently processed through third-party payment interface or platform. Payment methods through third-party payment interface or platform shall be available subject to any additional conditions or fees, as per the applicable terms and conditions of the third-party payment interface or platform.
                    </li>
                    <li>
                        f. Any payment related issue, except when such issue is due to an error or fault of the Application, shall be resolved between the Subscribers, and the third-party payment interface or platform. ADL shall not be responsible for the saving of payment data on the Application or any unauthorized use of the payment data of the Subscriber during or after availing the Services.
                    </li>
                    <li>
                        g. If payment through the available methods fails or is refused by the third-party payment interface or platform, ADL shall be under no obligation to provide the Services. Any possible costs or fees resulting from the failed or refused payment shall be borne by the Subscriber.
                    </li>
                    <br>
                    <li>h. <b>PROMO CODES & REFERRAL CODES</b></li>
                    <ol style="font-weight: 400; list-style: none; ">
                        <li>i. ADL may provide the Subscriber with promotional codes that may be redeemed during booking of Services, subject to any additional terms that ADL stipulates on a per promotional code basis <b>("Promo Codes").</b> </li>
                        <li>ii. On being provided with a Promo Code, the Subscriber agrees that:</li>
                        <ol style="font-weight: 400; list-style: none; ">
                            <li>I. it will be used by the Subscriber for the specific business for which the Promo Code has been provided; </li>
                            <li>II. it will be used in a lawful manner in accordance with the Applicable Law; </li>
                            <li>III. the Promo Code shall not be duplicated, sold, or transferred in any manner; and</li>
                            <li>IV. may be discontinued by ADL at any time for any reason without incurring any liability for such discontinuation.</li>
                        </ol>
                        <li>
                            iii. ADL may also provide the Subscriber with referral codes that may be used by the Subscriber to invite other persons <b>("Invitee")</b> to use the Application <b>("Referral Code")</b>. On use of such Referral Code, the Subscriber and the Invitee shall be eligible for such benefits as stipulated by ADL on a per Referral Code basis.
                        </li>
                        <li>iv. On using a Referral Code, the Subscriber and the Invitee agree that:</li>
                        <ol style="font-weight: 400; list-style: none; ">
                            <li>I. Such Referral Codes are strictly subject to availability;</li>
                            <li>II. Referral Codes are non-transferable, non-negotiable, and non-refundable, and have no cash value or cash alternative;</li>
                            <li>III. it will be used in a lawful manner in accordance with the Applicable Law;</li>
                            <li>IV. if the Referral Code is used incorrectly, the Subscriber and the Invitee shall not be eligible for any benefits granted by ADL through such Referral Code; and</li>
                            <li>V. the Referral Code cannot be used where the Invitee is a Franchisee Business unit of the Subscriber.</li>
                        </ol>
                        <li>v. The Referral Code or the Promo Code may be used only to such extent and as many times as may be allowed by ADL.</li>
                        <li>vi. The Referral Code or the Promo Code shall be redeemed or used in accordance with this T&C Agreement and any supplemental terms and conditions specified by ADL.</li>
                        <li>vii. The use of Referral Code or the Promo Code may be discontinued by ADL at any time for any reason without incurring any liability for such discontinuation.</li>
                        <li>viii.	To the fullest extent permitted by the Applicable Law, ADL shall not be liable for any loss or damage arising from use of the Referral Code or the Promo Code.</li>
                        <li>
                            ix.	ADL reserves the right to withhold or deduct from the Subscription Fee any amount, if the Subscriber applies the Promo Code or the Referral Code in error, or in a fraudulent or illegal manner in violation of the T&C Agreement or the Applicable Law.
                        </li>
                    </ol>
                </ol>
                <br>
                <b>4. SUBSCRIPTIONS</b>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>a. ADL allows Subscribers, to receive a Service through various Subscription models as may be determined by ADL and informed through the Subscription document.</li>
                    <li>b. The Subscription may be monthly, bi-monthly or for any period of time specified by ADL and may allow a Subscriber to register 1 (one) Business or multiple Businesses, on the Application. </li>
                    <li>
                        c. The details of benefits available and terms of the Subscription shall be set out in the Subscription document. In addition to the T&C Agreement, the Subscription shall be governed by the terms and conditions stipulated in the Subscription document.
                    </li>
                    <li>
                        d. The Subscription shall begin on the day of payment of the Subscription Fee. The Subscribers shall be entitled to the benefits provided under Subscription, only on timely payment of the recurring Subscription Fee. On expiry of the Subscription, the Subscriber shall not be allowed to access any of the Services or view the Report generated by the Application.
                    </li>
                    <li>e. The Subscriber agree Subscription model and the benefits offered, are subject to change, limited by location, and are contingent on the Business model.</li>
                </ol>
                <br>
                <b>5. BETA SERVICE</b>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        a. ADL may offer certain Services as closed or open beta services <b>("Beta Service")</b> for the purpose of testing and evaluation. The Subscriber agrees that ADL shall have the sole authority and discretion to determine the period of time for testing and evaluation of Beta Services <b>("Beta Phase"). </b>
                    </li>
                    <li>
                        b. ADL at its sole discretion, may extend the Beta Services to only a specified location or to a limited number of Subscribers, and other Subscribers shall have no claim against ADL, for providing the Beta Services on such discriminatory basis.
                    </li>
                    <li>c. ADL will be the sole judge of the success of such testing and the decision, if any, to offer the Beta Services as commercial services. </li>
                    <li>d. At the option of ADL, Subscriber may be exempted from making any payment to procure the Beta Services during the Beta Phase.</li>
                    <li>e. ADL reserves the right to fully or partially discontinue, at any time and from time to time, temporarily or permanently, any of the Beta Services with or without notice to the Subscriber.
                    </li>
                    <li>
                        f. The Subscriber agrees that ADL shall not be liable to the Subscriber or to any third-party for any harm related to, arising out of, or caused by the modification, suspension, or discontinuance of any of the Beta Services for any reason.
                    </li>
                    <li>
                        g. For the avoidance of doubt, the Beta Services may be provided to the Subscribers on a free trial basis till the time ADL approves the Application for commercial launch and notifies the Subscriber of the start date of the Subscription for the applicable Services.
                    </li>
                    <li>
                        h. Any data that the Subscriber has provided through the Application during the Beta Phase, and any customizations made to the Services during the Beta Phase shall be permanently lost unless the Subscriber purchase the corresponding Subscription for the Account at the end of the Beta Phase.
                    </li>
                    <li>
                        i. Save and except the obligation to procure a Subscription, the other terms and conditions of this T&C Agreement will continue to apply to the Subscribers during the Beta Phase.
                </ol>
                <br>
                <b>6. TERMINATION</b>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                       a. Notwithstanding any other rights available to ADL under this T&C Agreement, ADL may at its sole discretion suspend or terminate the provision of the Services and/or Subscription (where applicable) without prior notice and without liability to the Subscriber in any of the circumstances set out below: 
                    </li>
                    <ol style="font-weight: 400; list-style: none; ">
                        <li>i. If ADL decides to suspend or forego the launch of the Application basis the result obtained during the Beta Phase;</li>
                        <li>
                            ii. If ADL becomes aware or has reason to believe that the Services or any part thereof are being utilized by the Subscriber in an unauthorized, unlawful, or fraudulent manner, or has been compromised, or may have been used for criminal activities;
                        </li>
                        <li>iii. if the Subscriber, fails to make payment of the Subscription Fee or such other sums that are due and owing to ADL; </li>
                        <li>iv. if the Subscriber breaches or if ADL has reason to believe that the Subscriber may be in breach of this T&C Agreement; </li>
                        <li>v. if the Subscriber fails to accept the revised terms and conditions of the T&C Agreement or the Privacy Policy; and</li>
                        <li>
                            vi. for reasons outside the control of ADL, if there is an emergency, occurrence of Force Majeure event, for the health and safety of the Subscriber, and/or members of the public.
                        </li>
                    </ol>
                    <li>
                        b. The Subscriber can at any time request ADL to close or terminate the Subscriber's Account. Upon the closure or termination of the Subscriber's Account or the Subscription, for any reason whatsoever, Subscriber shall no longer be permitted to utilize any Services or view the Report, and ADL shall not be obligated to make available to the Subscriber any Services. For the avoidance of doubt, the Subscriber shall not evade any legal proceedings or investigations by closing or terminating the Account or the Subscription if the Account is terminated by ADL. The Subscriber shall remain liable for all obligations in relation to the Account even after the Account or the Subscription is cancelled or terminated. 
                    </li>
                    <li>
                        c. Where applicable, the closure or termination of the Account shall terminate the Subscription plan automatically. The Subscriber acknowledges that an early termination fee shall be imposed in accordance with the Subscription document if the Subscriber terminates the Account or the Subscription plan prematurely.
                    </li>
                    <li>
                        d. Within the limits of the Applicable Law, ADL may also decide to suspend or terminate the Service altogether. If the Service is terminated, ADL shall cooperate with Subscribers to enable them to withdraw personal data or information in accordance with the Privacy Policy and the Applicable Law.
                    </li>
                    <li>
                        e. ADL prohibits discrimination against third-party service providers or any of its personnel based on race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other characteristic that may be protected under the Applicable Law. ADL reserves the right to discontinue the provision of Services and recover the entirety of the Subscription Fee, if the Subscriber violates this policy by discriminating against the service providers or any Consumer.
                    </li>
                    <li>
                        f. In addition to the rights of termination set out above, ADL has the right to blacklist and restrict a Subscriber's access to the Application, if the Subscriber is in breach of any of its obligations under this T&C Agreement or the Applicable Law.
                    </li>
                </ol>
                <br>
                <b>7. REFUND AND CANCELLATION</b>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        a. The Subscribers agree and acknowledge that they shall not be entitled to cancel the Subscription during the Subscription period. For any cancellation by the Subscribers, a Cancellation Fee shall be charged at 100% of the Subscription Fee or Additional Fee (as applicable). However, ADL, on request of the Subscriber, may at its option, decide to refund a part of the Subscription Fee or Additional Fee to the Subscriber
                    </li>
                    <li>
                        b. Subscribers are not allowed to withdraw from the Subscription, without giving at least 15 (fifteen) business days' prior written notice to ADL. Notwithstanding anything mentioned hereinabove, on withdrawing from the Subscription without providing such notice, the Cancellation Fee shall be charged at 100% (one hundred percent) of the Subscription Fee.
                    </li>
                    <li>c. The Subscriber agrees that the Cancellation Fee stipulated in Clause F (7) (a) above, is subject to change and shall be notified by ADL through the Application.</li>
                    <li>
                        d. If cancellation is done by ADL in an event where the Application, or the Website is under maintenance or out of service, no Cancellation Fee shall be charged, and the Subscription Fee or the Additional Fee shall be refunded to the Subscriber.
                    </li>
                    <li>
                        e. Services by ADL are provided on a prepaid basis. On cancellation, the refund of the amount (if any), post any deduction of Cancellation Fee, shall be made within 30 (thirty) business days.
                    </li>
                </ol>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400; ">
                <b>G. LIABILITY AND INDEMNIFICATION</b>
            </span>
            <ol style="font-weight: 400;">
                <li>
                    While the content on this Application and the Website of ADL are presented with the greatest accuracy technically possible, representation on the Application or the Website through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the Service. The Website and the Application may be under constant upgrades, and some functions and features may not be fully operational.
                </li>
                <li>
                     The Service provided by ADL is obtained by the Subscribers at the Business location of such Subscribers. Subscribers are advised to supervise that the Consumers scan the QR Code post obtaining services or procuring products from the Subscriber. ADL shall have no responsibility or liability to the Subscriber in relation to the failure of the Subscriber to appropriately supervise and guide the Consumers.
                </li>
                <li>
                    ADL disclaims all representations and warranties, express, implied, or statutory, not expressly set out in this T&C Agreement, including any implied warranties of merchantability, fitness for a particular purpose and non-infringement, and makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the Services or the Report, or that the Services will be uninterrupted or error-free. ADL does not guarantee the quality, suitability, safety, or ability of third-party providers.
                </li>
                <br>
                <li><b>LIMITATIONS OF LIABILITY</b></li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        a. In no event shall ADL, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers, and employees be liable for:
                    </li>
                    <ol style="font-weight: 400; list-style: none; ">
                        <li>
                            i. any indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or relating to the use of, or inability to use, the Service or the Application;
                        </li>
                        <li>ii. any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service, the Application, the Website or Account or the information contained therein;</li>
                        <li>iii. any errors, mistakes, or inaccuracies of content;</li>
                        <li>iv. personal injury or property damage, of any nature whatsoever, resulting from Subscriber's access to the Application or the Website or use of the Services by the Subscriber;</li>
                        <li>v. any unauthorized access to or use of the ADL's secure servers and/or any and all personal information stored therein;</li>
                        <li>vi. any service unavailability or service interruptions related to third-party server maintenance and down time;</li>
                        <li>vii. any interruption or cessation of transmission to or from the Service;</li>
                        <li>viii.	any bugs, viruses, spyware, malware or the like that may be transmitted to or through the Service, the QR Code, the Application, or the Website;</li>
                        <li>
                            ix. any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Application, the QR Code or Website in relation to the Service; and/or
                        </li>
                        <li>x. the defamatory, offensive, or illegal conduct of any Subscriber.</li>
                    </ol>
                    <li>
                        b. In no event shall ADL, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers, and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses, or costs for an amount exceeding the Subscription Fee payable for a single instance of Subscription.
                    </li>
                    <li>
                        c. The Subscriber shall not be entitled to make any claim or initiate any proceedings against ADL or claim any damages for the data shared with other Subscribers, on an anonymized basis, as a part of providing Services under this T&C Agreement.
                    </li>
                    <li>
                        d. This limitation of liability section shall apply to the fullest extent permitted by the Applicable Law, whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if ADL has been advised of the possibility of such damage.
                    </li>
                </ol>
                <br>
                <li><b>INDEMNIFICATION</b></li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>
                        a. The Subscriber, agrees to defend, indemnify, and hold ADL and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers, and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from:
                    </li>
                    <ol style="font-weight: 400; list-style: none; ">
                        <li>i. Subscriber's use of and access to the Application or Website, or the procurement of Services by the Subscriber, including any data or content transmitted or received by the Subscriber;
                        </li>
                        <li>ii. Subscriber's violation of the terms of this T&C Agreement, including, but not limited to, the terms and conditions of the Subscription document;</li>
                        <li>iii. Subscriber's violation of any third-party rights, including, but not limited to, any right of privacy or Intellectual Property Rights (as defined hereinafter);</li>
                        <li>iv. Subscriber's failure to pay any statutory dues and taxes;</li>
                        <li>v. Claim of libel and defamation;</li>
                        <li>vi. violation of the Applicable Law by the Subscriber, or their affiliates, officers, directors, agents, co-branders, partners, suppliers, and employees;</li>
                        <li>
                            vii. any content that is submitted from Account, including third-party access with Subscriber's unique username, password, or other security measures, if applicable, including, but not limited to, misleading, false, or inaccurate information; or
                        </li>
                        <li>viii. viii.	Subscriber's willful misconduct.</li>
                    </ol>
                </ol>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400; ">
                <b>H. FORCE MAJEURE AND SUSPENSION</b>
            </span>
            <ol style="font-weight: 400; ">
                <li>
                    <b>"Force Majeure"</b> means any event which is beyond the reasonable control of ADL and which impacts the execution of its obligations under this T&C Agreement, including, but not limited to, natural disasters, extreme weather conditions, fire, riots, war and military operations, national or local emergency situations, acts or negligence of the government, import, export and/or transit prohibitions, economic disputes of any nature whatsoever, strikes or other labour actions, flooding, lightning, explosions, collapses, disruptions in traffic or power networks, the reduced or non-functioning of networks, systems or equipment of third parties as well as any act of negligence of a person or entity which is outside of the reasonable control of ADL.
                </li>
                <li>
                    In case of occurrence of a Force Majeure event, ADL is entitled to suspend or terminate the access to any of the Services by giving the Subscriber, a prior notice to the extent possible. In the event there are still outstanding payables, or charges for Services delivered until the termination date, the same shall be payable to ADL on a pro rata basis.
                </li>
                <li>
                    Notwithstanding anything mentioned elsewhere in the T&C Agreement, and in addition to the right of suspension available in Clause H (2) above, to ensure that optimum Service is provided, ADL reserves the right to interrupt the Service or access of the Subscriber to the Website and Application for maintenance, system updates or any other changes.
                </li>
                <li>
                    ADL shall not be in breach of this T&C Agreement nor liable for any delay or failure to comply with its obligations under this T&C Agreement if such delay or failure was the result of Force Majeure or occurred due to suspension for maintenance as stipulated in Clause H (2) and H (3) above. 
                </li>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400; ">
                <b>I. INTELLECTUAL PROPERTY RIGHTS</b>
            </span>
            <ol style="font-weight: 400; ">
                <li>
                    <b>"Intellectual Property Rights"</b> include, but are not limited to any rights, title and interest in patents, designs, copyrights, moral rights, database rights, trademarks, service marks, trade and business names, domain names, and any rights of goodwill associated therewith, rights to sue for passing off, rights in the nature of unfair competition rights, trade secrets, confidentiality and other proprietary rights including rights to know-how, rights to technical and other information, rights to apply for registration of any of the foregoing, rights to take action for past, present and future infringements in respect of any of the foregoing, and all rights in the nature of any of the foregoing anywhere in the world whether registered or unregistered whether subsisting now or in the future.
                </li>
                <li>
                    Without prejudice to any specific provision of this T&C Agreement, any Intellectual Property Rights, related to the Application or the Website or the Services provided are the exclusive property of ADL or its licensors and are subject to the protection granted under the Applicable Law or international treaties, covenants or legal, instruments relating to intellectual property.
                </li>
                <li>
                    All trademarks, whether nominal or figurative, and all other marks, trade names, service marks, word-marks, illustrations, images, or logos appearing in connection with this Application or on the Website of ADL are, and remain, the exclusive property of ADL or its licensors and are subject to the protection granted by the Applicable Laws or international treaties, covenants or legal, instruments related to intellectual property.
                </li>
                <li>The Subscriber, shall not:</li>
                <ol style="font-weight: 400; list-style: none; ">
                    <li>a. remove any Intellectual Property Right from any portion of the Services;</li>
                    <li>b. Infringe third party's Intellectual Property Rights;</li>
                    <li>c. reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services;</li>
                    <li>d. decompile, reverse engineer, or disassemble the Services, Application, Website, or any other content provided by ADL;</li>
                    <li>e. link to, mirror or frame any portion of the Services, Application, Website, or any other content provided by ADL;</li>
                    <li>f. cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise obtaining data on any portion of the Services;</li>
                    <li>g. attempt to derive source code (or the underlying ideas, algorithms, structure, or organization); or </li>
                    <li>h. attempt to gain unauthorized access to or impair any aspect of the Services or its related systems.</li>
                </ol>
                <li>
                    Intellectual Property Rights owned by third parties may appear on the Website or the Application, and all rights therein are reserved for use by such third parties who are registered owners of such Intellectual Property Rights. For use of any third-party's Intellectual Property Rights, the Subscriber is required to obtain permission directly from such third-party, and ADL shall not be liable to the Subscriber or the third-party for any infringement or unlawful use of such Intellectual Property Rights owned by third parties.
                </li>
                <li>
                    Notwithstanding anything mentioned in Clause E (2) of this T&C Agreement, no license or right is granted and the Subscriber's access to and/or use of the content of the Application and/or Website and/or the Services should not be construed as granting, by implication, estoppel or otherwise, a license or right to use any Intellectual Property Rights in the content of the Application and/or Website and/or Services without the prior consent of ADL
                </li>
            </ol>
        </div>
        <div>
            <span style="font-weight: 400; ">
                <b>J. CONFIDENTIALITY</b>
            </span>
            <ol style="font-weight: 400; ">
                <li><b>"Confidential Information"</b> shall mean any information disclosed by ADL and/or its promoters, directors, and/or its officers, employees, advisors, representatives and agents to the Subscriber under this T&C Agreement, either directly and/or indirectly which relates to the Business and Services of ADL in any form whatsoever including, without limitation, any documents, databases, research and development, manufacturing methods, formulas, process, samples, messenger, messaging tool, concepts, prototypes, discs and code, Intellectual Property Rights, strategies, network configurations, computer program, designs, flow charts, drawings, proprietary information, business and marketing plans, market information, trade secrets, financial and operational information, list of service providers and/ or suppliers and/ or consumers and their terms of business, details of its employees and officers, all technical and business information and/ or any other either commercially valuable or non-commercial, marketing plans, management systems, finances, accounts, forecasts, compilations, studies, summaries, extracts disclosed by ADL.</li>
                <li>
                    The Subscriber hereby undertakes and covenants to maintain complete confidentiality with respect to the Confidential Information disclosed in course of providing the Services under this T&C Agreement and shall not use, divulge or disclose any of the Confidential Information to any third-party, firm and/or body corporate whether for commercial gain or otherwise in any manner whatsoever without the prior written consent of ADL and shall use the Confidential Information solely for the purpose set out under this T&C Agreement.
                </li>
                <li>
                    The Subscriber agrees and understands that because of the unique nature and sensitivity of the Confidential Information, ADL will suffer irreparable harm/loss in the event the Subscriber fails to comply with its confidentiality obligations, and that monetary damages will be inadequate to compensate ADL for such breach. The Subscriber, therefore, agrees that ADL, in addition to its rights in law and equity including its right to claim damages, shall have the right to seek immediate injunctive relief and specific performance to enforce the confidentiality obligations contained herein.
                </li>
                <li>The confidentiality obligations shall be binding on the Subscriber from the date of opening of the Account and shall survive the expiry or termination of this T&C Agreement.</li>
                <li>
                    It is hereby expressly provided that in the event the Subscriber breaches its confidentiality obligations set out hereinabove, the Subscriber shall be liable for criminal proceedings without prejudice to the other rights and remedies available to ADL under the Applicable Law and this T&C Agreement.
                </li>
            </ol>

        </div>

        <p>
            <span style="font-weight: 400; ">
                <b>K.  ALTERATION OF TERMS AND SERVICES</b>
                <ol style="font-weight: 400; ">
                    <li>ADL is entitled to vary the Services, including the locations serviceable, at any time. Further, ADL reserves the right to make changes to the Website, Application, Privacy Policy, and the T&C Agreement at any time </li>
                    <li>The continued use of the Service shall signify the Subscriber's acceptance of the revised T&C Agreement and the Services. If Subscribers do not wish to be bound by the changes, they shall be entitled to stop using the Application, the Website, or procure Services from ADL. Failure to accept the revised T&C Agreement shall entitle ADL to terminate the T&C Agreement.</li>
                    <li>The applicable previous version of the T&C Agreement shall govern the relationship prior to the Subscriber's acceptance. ADL shall specify the date by which the modified terms are effective on the Services rendered.</li>
                </ol>
            </span>
        </p>

        <p>
            <span style="font-weight: 400; ">
                <b>L. ASSIGNMENT OF CONTRACT</b>
                <ol style="font-weight: 400; ">
                    <li>ADL reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under this T&C Agreement, taking the Subscriber's legitimate interests into account.  </li>
                    <li>Subscriber shall not assign or transfer their rights or obligations under this T&C Agreement in any way, without the written permission of ADL.</li>
                </ol>
            </span>
        </p>
        <p>
            <span style="font-weight: 400; ">
                <b>M. CONTACT</b><br>All communications relating to the use of the Application must be sent using the contact information stated below.<br>
            </span>
            <b>Avidestal Technologies Private Limited <br>
            Bengaluru, India<br>
            Contact email</b>: support@me-grow.com<br>
        </p>
        <p>
            <span style="font-weight: 400; ">
                <b>N. SEVERABILITY</b><br>Should any provision of this T&C Agreement be deemed or become invalid or unenforceable under Applicable Law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.
            </span>
        </p>
        <p>
            <span style="font-weight: 400; ">
                <b>O. GOVERNING LAW</b><br>This T&C Agreement is governed by the law of the place where ADL is based, as disclosed in the relevant section of this document. The exclusive competence to decide on any dispute resulting from or connected to this T&C Agreement lies with the courts of the place where ADL is based i.e., Bengaluru, Karnataka.
            </span>
        </p>
        <p>
            <span style="font-weight: 400; ">
                <b>P. WAIVER</b><br>ADL's failure to assert any right or provision under this T&C Agreement shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.
            </span>
        </p>
        <p>
            <span>
                <b>Q. DISPUTE RESOLUTION</b>
                <ol>
                    <b>1. AMICABLE DISPUTE RESOLUTION</b>
                    <ol style="list-style: none; font-weight: 400; ">
                        <li>a. Subscribers are entitled to bring any dispute to ADL, by notifying through the official contact details provided in this T&C Agreement. The Subscriber shall submit the complaint including a brief description and if applicable, the details of the related order, purchase, or Account.</li>
                        <li>b. ADL shall consider the nature of the dispute, and the Subscriber, and ADL shall endeavor to resolve them amicably.</li>
                        <li>c. ADL shall process the complaint without undue delay and in any case within 60 (sixty) days of receiving it.</li>
                    </ol>
                    <br>
                    <b>2. ARBITRATION</b>
                    <ol style="font-weight: 400; ">
                        <li>a. If a dispute, controversy or claim (hereinafter referred to as <b>("Dispute(s)")</b> arises out of or in connection with this T&C Agreement or the Services provided thereunder, including any question regarding its existence, validity, termination or any issue over unpaid payments or fees payable to Services, and the same is not resolved amicably within 60 (sixty) days of notification to ADL, then the same shall be referred to and finally resolved by arbitration in accordance with the Arbitration and Conciliation Act, 1996, or any amendments thereof.</li>
                        <li>b. The arbitral tribunal shall consist of 3 (three) arbitrators. The seat of arbitration shall be at Bengaluru, Karnataka, and the language of the arbitration proceedings shall be English. The order of the arbitral tribunal shall be binding on both the parties to this T&C Agreement. Without prejudice to the aforementioned arbitration agreement, the courts of appropriate jurisdiction in Karnataka shall have exclusive jurisdiction to adjudicate any issue or dispute arising out of or in connection with the aforementioned arbitration agreement or any proceedings in accordance with the Arbitration and Conciliation Act, 1996 or any amendments thereof.</li>
                    </ol>
                </ol>
            </span>
        </p>
        <h1 style=" text-align:center;">
            *** End of Terms and Conditions***
            <br>
        </h1>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>