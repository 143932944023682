import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ErrorConfigInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
        } 
        if(event.body && (event.body.status_code === 1806 || event.body.status_code === 1014) && !request.url.includes('sign-out')) {
          // this.auth.loginPopupObserver.next(true);
        } else {
          // this.auth.loginPopupObserver.next(false);
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 400:
              // this.toastr.error(err.error.message, this.constant.ERROR);
              break;
            case 401:
              // this.handleAuthenticationError(err);
              break;
            case 403:
              // this.toastr.error(this.constant.NOT_ALLOWED, this.constant.ERROR);
              break;
            case 404:
              break;
            case 409:
              // this.toastr.error(err.error.message, this.constant.ERROR);
              break;
            default:
             if(err.error.text) {
              break;
             }
              // this.toastr.error(
              //   "Something went wrong, Please try again.",
              //   this.constant.ERROR
              // );
              break;
             
          }
        }
        return throwError(err.error);
      })
    );
  }
}
