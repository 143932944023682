import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/shared/services/utility/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  showProfile = false;
  urlPath:string = '';
  roleId:any;
  constructor(
    private utils: UtilService,
    private router:Router,
  ) { 
    router.events.subscribe((val) => {
      this.urlPath = router.url;
      if(val instanceof NavigationEnd){
        this.showMenu = this.urlPath != '/my-qrcode'
        && this.urlPath != '/payment-failed';
        this.showProfile = this.urlPath != '/business-profile'
        && !this.urlPath.includes('/payment-details');
      }
    });
  }

  ngOnInit(): void {
    this.roleId = this.utils.getStoreDetails()?.roleId;
  }

  logout() {
    this.utils.logout();
  }
}
