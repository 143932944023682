import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UtilService } from '../../services/utility/util.service';
import { ValidationService } from '../../services/validation/validation.service';
import { Subject } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {takeUntil} from 'rxjs/operators';
import { CommonService } from '../../services/common/common.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MMM',
  },
  display: {
    dateInput: 'DD MMMM',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-customer-filter-sheet',
  templateUrl: './customer-filter-sheet.component.html',
  styleUrls: ['./customer-filter-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}]
})
export class CustomerFilterSheetComponent implements OnInit {
  dateMonthHeader = DateMonthHeader;
  filterForm!:UntypedFormGroup;
  genderList:any[] = [];
  npsList:any[] = [];
  ageGroupList:any[] = [];
  uniqueIdList:any[] = [];
  nameList:any[] = [];
  
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data:any,
    private _bottomSheetRef: MatBottomSheetRef<CustomerFilterSheetComponent>,
    private formBuilder: UntypedFormBuilder,
    private util: UtilService,
    private validation: ValidationService,
    private common: CommonService
    ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.genderList = this.common.getGenderListWithAll()
      this.npsList = this.common.getNpsList()
      this.f['gender'].setValue(this.data.gender)
      this.f['age'].setValue(this.data.age)
      this.f['nps'].setValue(this.data.nps)
      this.ageGroupList = this.common.getAgeGroupList()
    });
    if(this.data.dateOfBirth && this.data.dateOfBirth !== null){
      this.data.dateOfBirth = "0000-"+this.data.dateOfBirth;
    }
    this.filterForm = this.formBuilder.group({
      gender:[this.data.gender],
      age:[this.data.age],
      name:[this.data.name],
      dateOfBirth:[this.data.dateOfBirth],
      nps:[this.data.nps],
    });
  }

  applyFilter(event: MouseEvent): void {
    let data = this.filterForm.value;
    if(data.start_date && data.end_date){
      data.start_date = this.util.formatDateAsc(data.start_date);
      data.end_date =  this.util.formatDateAsc(data.end_date);
    }
    this._bottomSheetRef.dismiss(this.filterForm.value);

    event.preventDefault();
  }

  closeBottomSheet(event: MouseEvent){
    this._bottomSheetRef.dismiss('reset');
    event.preventDefault();
  }

  get f(){
    return this.filterForm.controls;
  }

  removeValidators(field:string){
    this.util.removeValidators(this.filterForm,field)
  }

  getErrMessage(abstractControl:AbstractControl) {
    return this.validation.getErrorMessage(abstractControl);
  }
}



/** Custom header component for datepicker. */
@Component({
  selector: 'date-month-header',
  styles: [`
    .example-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }

    .example-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }
  `],
  template: `
    <div class="example-header">
       <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="example-header-label">{{periodLabel}}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateMonthHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
      private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
        .format(this._calendar.activeDate,this._dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
  }

  previousClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  nextClicked(mode: any) {
    this._calendar.activeDate =  this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
