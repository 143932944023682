import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { UtilService } from 'src/app/shared/services/utility/util.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  showToast:boolean = false;
  toastMessage:string = '';
  constructor(
    private router: Router,
    private util: UtilService,
    private common: CommonService
  ) { 
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.checkPlanExpired(router.url)
      }
    });
  }

  ngOnInit(): void {
    this.common.setLanguage(this.common.getLanguage())
    let interval = setInterval(()=> {
      let subs = this.util.getSubs();
      this.checkPlanExpired(this.router.url)
      if(Object.keys(subs).length !== 0){
        clearInterval(interval);
      }
    },500)
  }

  checkPlanExpired(path:any){
    let subs = this.util.getSubs();
    let plan = this.util.getPlanData();
    this.toastMessage = plan?.show_plan_extension ? plan?.plan_extension_message : subs?.planUpgradeMessage;
    this.showToast = this.util.getStoreDetails()?.roleId != 2 && (plan?.show_plan_extension || subs?.showUpgradeMessage)
    && path == '/dashboard';
  }

  @HostListener('click', ['$event'])
  onClick(e:any) {
    if (e.target.classList.contains("upgrade-toaster")) {
      this.router.navigate(['/choose-plan']);
    };
  }

}
