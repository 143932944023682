import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilService } from '../../services/utility/util.service';
declare var Fingerprint2: any;
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private router:Router,
    private utils:UtilService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = this.utils.getToken();
      if (token) {
        this.router.navigateByUrl('/dashboard')
        return false;
      } else {
        return true;
      }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = this.utils.getToken();
      if (token) {
        this.router.navigateByUrl('/dashboard')
        return false;
      } else {
        return true;
      }
  }

}
