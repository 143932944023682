<mat-dialog-content>
    <div class="main tc-popup-section">
        <h1 class="text-dark title">{{'subscribe_modal.why_should_i_subscribe'|translate}}</h1>
        <div class="description">
            <span class="text-dark mb-2">{{'subscribe_modal.subscribing_business_name'|translate:{business:businessName} }}</span>
            <span class="text-dark mb-2">{{'subscribe_modal.will_receive_latest_update'|translate:{business:businessName} }}</span>
            <span class="text-dark mb-2" [innerHtml]="'subscribe_modal.special_offers_festivals'|translate"></span>
            <span class="text-dark mb-2" [innerHtml]="'subscribe_modal.you_get_chance_enter_monthly'|translate"></span>
            <span class="text-dark mb-2">{{'subscribe_modal.contact_whatsapp_phone'|translate}}</span>
            <span class="text-dark mb-2">{{'subscribe_modal.business_completely_optional_feedback'|translate}}</span>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>{{'my_qrcode.close'|translate}}</button>
</mat-dialog-actions>