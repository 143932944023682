<mat-toolbar color="primary" class="mat-elevation-z8 position-fixed">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class=" logo-wrapper logo-section">
          <a href="javascript:void(0)" routerLink="/dashboard"><img src="assets/images/logo2.svg" alt="Survey-logo"></a>
          <!-- <span>AvidIntelli</span> -->
      </div>
      <span class="example-spacer"></span>
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showMenu">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-menu #menu="matMenu">
    <button *ngIf="showProfile" routerLink="/edit-business-profile" mat-menu-item>
      <mat-icon>person</mat-icon> 
      <span>{{'title.profile' | translate}}</span>
    </button>
    <button *ngIf="showProfile && roleId != 2" mat-menu-item routerLink="/subscriptions">
      <mat-icon>subscriptions</mat-icon> 
      <span>{{'title.subscription' | translate}}</span>
    </button>
    <button *ngIf="showProfile" mat-menu-item routerLink="/setup-survey">
      <mat-icon>settings</mat-icon> 
      <span>{{'title.gt' | translate}}</span>
    </button>
    <button *ngIf="showProfile" mat-menu-item routerLink="/strategy-view">
      <mat-icon>insights</mat-icon> 
      <span>{{'title.insights_lab' | translate}}</span>
    </button>
    <button *ngIf="showProfile" mat-menu-item routerLink="/digital-marketing">
      <mat-icon>campaign</mat-icon> 
      <span>{{'title.cust_engage' | translate}}</span>
    </button>
    <button *ngIf="showProfile" mat-menu-item routerLink="/languages">
      <mat-icon>language</mat-icon> 
      <span>{{'title.language' | translate}}</span>
    </button>
    <button *ngIf="showProfile && roleId != 2" mat-menu-item routerLink="/store-management/stores">
      <mat-icon>store</mat-icon> 
      <span>{{'title.store_management'|translate}}</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon> 
        <span>{{'title.logout' | translate}}</span>
    </button>
  </mat-menu>
