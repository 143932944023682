import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  apiUrl = environment.apiUrl;

  constructor(
    private httpClient:HttpClient
  ) { }

  getBusinessList(data:any) {
    let headers = new HttpHeaders({
      'skip':'true',
      'Accept':'application/json',
    })
    return this.httpClient.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${data.input}&language=${data.language}&types=${data.types}&key=${data.key}`,
    {headers:headers});
  }

  getIndustryList() {
    return this.httpClient.post(`${this.apiUrl}/store/get-industary`, null);
  }

  getOnboardingList() {
    return this.httpClient.get(`${this.apiUrl}/store/get-onboarding-list`);
  }

  getCityList() {
    return this.httpClient.get(`${this.apiUrl}/store/get-cities-list`);
  }

  submitBusinessProfile(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/add-store`, data);
  }
  
  getUserDetails() {
    return this.httpClient.post(`${this.apiUrl}/user/get-user-details`, null);
  }

  getStoreDetails() {
    return this.httpClient.post(`${this.apiUrl}/store/get-store-details`, null);
  }

  checkPlanExist() {
    return this.httpClient.get(`${this.apiUrl}/subscription/check-plan-exist`);
  }

  //******Choose plan***************** 
  getPlanList() {
    return this.httpClient.get(`${this.apiUrl}/subscription/get-plans`);
  }

  purchasePlan(data: any) {
    return this.httpClient.post(`${this.apiUrl}/subscription/purchase`, data);
  }

  checkout(data: any) {
    return this.httpClient.post(`${this.apiUrl}/auth/checkout`, data);
  }

  getOrderDetails(data: any) {
    return this.httpClient.post(`${this.apiUrl}/subscription/get-order-details`, data);
  }

  downloadSubscriptionInvoice(data: any) {
    return this.httpClient.post(`${this.apiUrl}/subscription/download-subscription-invoice`, data);
  }

  //******Template list***************** 
  getTemplateList() {
    return this.httpClient.get(`${this.apiUrl}/survey/get-templates`);
  }

  getTemplateData(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/get-template-data`, data);
  }

  //******Strategy Template list***************** 
  getStrategyList() {
    return this.httpClient.post(`${this.apiUrl}/survey/get-strategy-lists`,null);
  }

  downloadInsights(data:any) {
    return this.httpClient.post(`${this.apiUrl}/survey/download-insight`,data);
  }
  
//******Launch Survey***************** 

  showInstruction() {
    return this.httpClient.get(`${this.apiUrl}/survey/show-instruction`);
  }
  launchSurvey(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/launch-survey`, data);
  }

  launchMobileSurvey(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/launch-mobile-survey`, data);
  }

  uploadContacts(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/upload-contacts`, data);
  }

  uploadContactsFile(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/upload-contacts-file`, data);
  }

  setEndPage(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/set-endpage-url`, data);
  }

  checkExistingSurvey() {
    return this.httpClient.get(`${this.apiUrl}/survey/check-existing-survey`);
  }

  /********* Survey reports*********/

  getSurveyReports(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/strategy-view`, data);
  }

  //******Call list***************** 
  getCallList(data:any) {
    return this.httpClient.post(`${this.apiUrl}/avidviews/call-list`,data);
  }

  createCall(data: any) {
    return this.httpClient.post(`${this.apiUrl}/avidviews/create-call`, data);
  }

  getCallDetail(data: any) {
    return this.httpClient.post(`${this.apiUrl}/avidviews/get-call`, data);
  }

  updateCall(data: any) {
    return this.httpClient.post(`${this.apiUrl}/avidviews/update-call`, data);
  }
  // 
  generatePdf(data: any) {
    return this.httpClient.post(`${this.apiUrl}/subscription/genrate-qr-pdf`, data);
  }

  getCompanySubs() {
    return this.httpClient.get(`${this.apiUrl}/subscription/get-company-subscription`);
  }

  sendParticipantInvite(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/send-participant-invitation`, data);
  }

  storeConsent(data: any) {
    return this.httpClient.post(`${this.apiUrl}/survey/store-consent`, data);
  }

  getSubscription() {
    return this.httpClient.post(`${this.apiUrl}/subscription/getSubscription`, null);
  }

  transactionsList() {
    return this.httpClient.post(`${this.apiUrl}/subscription/transactions`, null);
  }

  authCallback(data: any) {
    return this.httpClient.post(`${this.apiUrl}/auth/call_back`, data);
  }
  // Welcome 

  getWelcomeData() {
    return this.httpClient.get(`${this.apiUrl}/subscription/welcome`);
}
  //Customer List
  getCustomerList(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/get-customer-list`, data);
  }

  exportCustomerList(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/export-customer-list`, data);
  }
  sendotpcustomerslist(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/send-otp-for-customers-list`, data);
  }
  resendotpcustomerslist(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/resend-otp-for-customers-list`, data);
  }
  verifyotpcustomerslist(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/verify-otp-for-customers-list`, data);
  }
  getBroadcastTemplate(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/get-broadcast-sms-templates`, data);
  }

  getBroadcastWhatsappTemplate(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/get-broadcast-whatsapp-templates`, data);
  }

  getRemainingSMSCredits(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/get-remaining-sms-credits`, data);
  }
  processCustomerCampaign(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/process-customer-campaign-data`, data);
  }
  sendBroadcastSMS(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/send-broadcast-sms`, data);
  }

  sendBroadcastWhatsapp(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/create-whatsapp-message`, data);
  }
  // update profile //
  updateStore(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/update-profile`, data);
  }

  getProfile() {
    return this.httpClient.get(`${this.apiUrl}/store/get-profile`);
  }

  verifyRequest(data: any) {
    return this.httpClient.post(`${this.apiUrl}/store/verify-request`, data);
  }

  //Import Contacts
  importContact(data: any) {
    return this.httpClient.post(`${this.apiUrl}/customer/create`, data);
  }

  importContactsFile(data: any) {
    return this.httpClient.post(`${this.apiUrl}/customer/bulk-import`, data);
  }

  // History

  getHistroy(data:any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/history`,data);
  }

  getCustomers(data:any) {
    return this.httpClient.post(`${this.apiUrl}/campaign/customers`,data);
  }

  // Automation
  getAutoTemplates(data:any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/get-auto-templates`,data);
  }

  getWhatsappAutoTemplates(data:any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/get-whatsapp-auto-templates`,data);
  }

  getAutoMessages(data:any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/get-auto-messages`,data);
  }

  saveAutoMessage(data: any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/save-auto-message`, data);
  }

  saveWhatsappAutoMessage(data: any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/save-whatsapp-auto-message`, data);
  }

  instantWhatsappAutoMessage(data: any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/save-whatsapp-instant-message`, data);
  }

  changeAutoMessageStatus(data: any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/change-auto-messages-status`, data);
  }

  changeWhatsappAutoMessageStatus(data: any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/change-whatsapp-auto-messages-status`, data);
  }

  deleteAutomation(data: any) {
    return this.httpClient.post(`${this.apiUrl}/marketing/delete-whatsapp-automessage`, data);
  }

  getInstantOffer() {
    return this.httpClient.get(`${this.apiUrl}/marketing/get-instant-offer`);
  }

  getInstantWhatsappOffer() {
    return this.httpClient.get(`${this.apiUrl}/marketing/get-whatsapp-instant-offer`);
  }

  saveCustomers(data: any) {
    return this.httpClient.post(`${this.apiUrl}/automessages/save-customer`, data);
  }

  getAutoCustomers(data: any) {
    return this.httpClient.post(`${this.apiUrl}/automessages/get-customers`, data);
  }

  //View statistics

  getSmsStats(data: any) {
    return this.httpClient.post(`${this.apiUrl}/merchant/get-sms-stats`, data);
  }

  getStatsOverview(data: any) {
    return this.httpClient.post(`${this.apiUrl}/merchant/stats-overview`,data);
  }

  statsSmsUndelivered(data: any) {
    return this.httpClient.post(`${this.apiUrl}/merchant/stats-sms-undelivered`, data);
  }

  //Whatsapp Business

  checkWhatsappStatus() {
    return this.httpClient.post(`${this.apiUrl}/whatsapp/checkStatus`, null);
  }

  sendWhatsappOtp(data: any) {
    return this.httpClient.post(`${this.apiUrl}/whatsapp/otp`,data);
  }

  verifyWhatsappOtp(data: any) {
    return this.httpClient.post(`${this.apiUrl}/whatsapp/verifyOtp`, data);
  }

  uploadWhatsappDocuments(data: any) {
    return this.httpClient.post(`${this.apiUrl}/whatsapp/uploadDocs`, data);
  }

  resubmitWhatsapp(docId:any, data: any) {
    return this.httpClient.post(`${this.apiUrl}/whatsapp/resubmit/${docId}`, data);
  }

  deregisterWhatsapp(data:any) {
    return this.httpClient.post(`${this.apiUrl}/whatsapp/deregister`, data);
  }

  // Store management
  getStoreList() {
    return this.httpClient.get(`${this.apiUrl}/store/get-stores-list`);
  }

  getUserList() {
    return this.httpClient.get(`${this.apiUrl}/company/users`);
  }

  createStore(data:any) {
    return this.httpClient.post(`${this.apiUrl}/store/create`,data);
  }

  getStore(data:any) {
    return this.httpClient.post(`${this.apiUrl}/store/get-store`,data);
  }

  deleteStore(data:any) {
    return this.httpClient.post(`${this.apiUrl}/store/delete-store`,data);
  }

  getAllStoreList() {
    return this.httpClient.get(`${this.apiUrl}/store/get-all-stores-list`);
  }
}
